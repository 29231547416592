._overflow {
  &-hidden {
    overflow: hidden;
  }
  &-auto {
    overflow-x: auto;
    overflow-y: auto;
  }
  &-y-auto {
    overflow-y: auto;
  }
  &-y-hidden {
    overflow-y: hidden;
  }
  &-x-auto {
    overflow-x: auto;
  }
  &-x-hidden {
    overflow-x: hidden;
  }
}

._ellipsis {
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
._one-row {
  -webkit-line-clamp: 1;
}
._two-rows {
  -webkit-line-clamp: 2;
}
._three-rows {
  -webkit-line-clamp: 3;
}
._four-rows {
  -webkit-line-clamp: 4;
}
