.messenger-btn { // todo: change class name
  left: unset;
  right: 30px;
  bottom: 30px;
  padding: 8px;
  z-index: 999;
  display: flex;
  position: fixed;
  line-height: 18px;
  border-radius: 4px;
  font-size: var(--font-size-m);
  background: var(--color-white);
  box-shadow: var(--box-shadow-primary);
  color: var(--text-color-primary-dark);
  &__text {
    font-weight: 600;
    margin-left: 9px;
    word-spacing: -.5px;
    letter-spacing: -.3px;
    font-family: var(--font-family-poppins);
  }
  &__icon {
    width: 21px;
    height: 16px;
    fill: var(--short-logo-color);
  }
}

.genesys-mxg {
  &-frame {
    z-index: 999 !important;
  }
  &-launcher-frame {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    pointer-events: none;
  }
}

._hide-genesys-btn {
  .messenger-btn {
    z-index: -1;
    display: none;
  }
}
