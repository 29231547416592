.custom-error {
  &._show-error {
    position: relative;
    margin-bottom: 30px;
    border: 1px solid var(--color-warning);
    border-radius: var(--base-border-radius);
  }
  &__message {
    left: 0;
    z-index: 1;
    line-height: 1;
    font-size: 10px;
    font-weight: 600;
    padding: 6px 12px;
    position: absolute;
    letter-spacing: .4px;
    top: calc(100% + 6px);
    color: var(--color-warning);
    background-color: var(--color-warning-secondary);
    border-radius: var(--base-border-radius);
    &:before {
      width: 0;
      height: 0;
      z-index: 1;
      top: -9px;
      left: 12px;
      content: '';
      position: absolute;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid var(--color-warning-secondary);
    }
  }
}
