.mat-mdc-dialog-container {
  padding: 0 !important;
  height: auto !important;
  font-family: var(--font-family-poppins);
  background-color: var(--bg-color-primary-tint);
  border-radius: var(--base-border-radius) !important;
  & > * {
    color: var(--text-color-secondary-alternative);
  }
}
