._list-style {
  &-circle {
    list-style: circle;
    &-inside {
      list-style: circle inside;
    }
  }
  &-decimal {
    list-style: decimal;
    &-inside {
      list-style: decimal inside;
    }
  }
  &-disc {
    list-style: disc;
  }
  &-inside {
    list-style: inside;
  }
  &-none {
    list-style: none;
  }
  &-custom-circle {
    padding-left: 16px;
    & > li {
      position: relative;
      &:after {
        top: 50%;
        left: -20px;
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        border-radius: 50%;
        transform: translateY(-50%);
        border: 2px solid var(--color-primary);
      }
    }
  }
}
