.custom-menu {
  position: relative;
  &__list {
    position: absolute;
    left: 100%;
    top: 100%;
    background: white;
    z-index: 2;
  }
  &__item {
    padding: 0 10px;
    &-action {
      line-height: 30px;
      white-space: nowrap;
    }
  }
}
