._ellipsis {
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

._one-row {
  -webkit-line-clamp: 1;
}

._two-rows {
  -webkit-line-clamp: 2;
}

._three-rows {
  -webkit-line-clamp: 3;
}

._four-rows {
  -webkit-line-clamp: 4;
}

._five-rows {
  -webkit-line-clamp: 5;
}

._row-ellipsis {
  display: block;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
