// material styles
@import "assets/styles/material/mat-form-field";
@import "assets/styles/material/mat-icons";
@import "assets/styles/material/mat-buttons";
@import "assets/styles/material/mat-menu";
@import "assets/styles/material/mat-dialog";
@import "assets/styles/material/mat-radio";
@import "assets/styles/material/mat-slide-toggle";
@import "assets/styles/material/mat-checkbox";
@import "assets/styles/material/mat-table";
@import "assets/styles/material/mat-tabs";
@import "assets/styles/material/mat-progress-bar";

// base styles
@import "assets/styles/fonts/fonts.css";
@import "assets/styles/base/optimize";
@import "assets/styles/base/default";
@import "assets/styles/base/variables";
@import "assets/styles/base/media-queries";
@import "assets/styles/mixin/mixin";
@import "assets/styles/atomic/index";

// data-table
@import "assets/styles/data-table/data-table";

// ngx-paginator
@import "assets/styles/ngx-paginator/ngx-paginator";

// custom styles
@import "assets/styles/custom/forms";
@import "assets/styles/custom/error";
@import "assets/styles/custom/buttons";
@import "assets/styles/custom/loading";
@import "assets/styles/custom/modals";
@import "assets/styles/custom/table";
@import "assets/styles/custom/menu";
@import "assets/styles/custom/paginator";
@import "assets/styles/custom/pspdfkit-viewer";

// messenger
@import "assets/styles/messenger/messenger";
