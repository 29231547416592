.mat-mdc-radio-button {
  display: inline-block;
  &:not(:last-child) {
    margin-right: 20px;
  }
  &.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle {
    z-index: 1;
    border-width: 4px !important;
    background-color: var(--color-white);
    --mdc-radio-selected-icon-color: var(--toggle-items-primary-color);
    --mdc-radio-selected-hover-icon-color: var(--toggle-items-primary-color);
    --mdc-radio-selected-pressed-icon-color: var(--toggle-items-primary-color);
  }
  &._error .mdc-radio__outer-circle {
    --mdc-radio-selected-icon-color: var(--color-warning);
    --mdc-radio-selected-pressed-icon: var(--color-warning);
    --mdc-radio-selected-hover-icon-color: var(--color-warning);
  }
  &._label-flex-start {
    .mdc-form-field {
      align-items: flex-start;
    }
  }
  &._label-s {
    .mdc-form-field {
     font-size: var(--font-size-ssm);
    }
  }
  .mdc-form-field {
    line-height: inherit;
    font-weight: inherit;
    letter-spacing: .4px;
    white-space: pre-wrap;
    font-size: var(--font-size-md);
    color: var(--text-color-primary);
    font-family: var(--font-family-poppins);
    .mdc-radio {
      padding: 0;
      &__outer-circle {
        border-width: 4px !important;
        --mdc-radio-unselected-icon-color: var(--toggle-items-secondary-color);
        --mdc-radio-unselected-hover-icon-color: var(--toggle-items-secondary-color);
        --mdc-radio-unselected-pressed-icon-color: var(--toggle-items-secondary-color);
      }
      &__outer-circle,
      &__inner-circle {
        width: 18px !important;
        height: 18px !important;
      }
      &__inner-circle {
        display: none;
      }
      &__background {
        &:before {
          display: none;
        }
      }
      .mdc-radio__native-control {
        --mdc-radio-state-layer-size: 20px;
      }
      &--disabled {
        opacity: .7;
      }
    }
    .mat-radio-ripple {
      display: none;
    }
    .mdc-label {
      padding-left: 8px;
      line-height: 18px;
      white-space: pre-wrap;
      font-family: var(--font-family-poppins);
    }
  }
}

.mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle,
.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mat-radio-persistent-ripple,
.mat-mdc-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--color-white);
}
