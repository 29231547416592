.mat-mdc-slide-toggle {
  .mdc-switch {
    &__track {
      width: 27px !important;
      height: 11px !important;
      &:before {
        background-color: var(--color-secondary-mediym) !important;
      }
      &:after {
        background-color: var(--color-primary) !important;
      }
    }
    &__handle {
      width: 16px !important;
      height: 16px !important;
    }
    &__icons {
      display: none;
    }
  }
  .mdc-label {
    padding-left: 0;
    font-weight: 500;
    letter-spacing: normal;
    font-size: var(--font-size-ssm);
    color: var(--text-color-primary);
    font-family: var(--font-family-poppins);
  }
  .mdc-switch.mdc-switch--unselected .mdc-switch__handle::after{
    background-color: var(--color-white) !important;
  }
  .mdc-switch.mdc-switch--selected {
    .mdc-switch {
      &__handle:after {
        background-color: var(--color-white) !important;
      }
      &__ripple:after {
        background-color: var(--color-primary) !important;
      }
    }
  }
}
