//noinspection CssUnknownProperty
.base-spinner {
  font-size: 3px;
  position: absolute;
  text-indent: -9999em;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  transform: translateZ(0);
  -ms-transform: translateZ(0);
  border-left: 1.3em solid #0088F7;
  -webkit-transform: translateZ(0);
  animation: loading 1.1s infinite linear;
  border-top: 1.3em solid rgba(#e0f3ff, 0.4);
  border-right: 1.3em solid rgba(#e0f3ff, 0.4);
  border-bottom: 1.3em solid rgba(#e0f3ff, 0.4);
  -webkit-animation: loading 1.1s infinite linear;
  &, &:after {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  &__wrapper {
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1025;
    overflow: hidden;
    position: absolute;
    background: rgba(255, 255,255, 0.7);
  }
}

@keyframes loading {
  //noinspection CssUnknownProperty
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  //noinspection CssUnknownProperty
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

._spinner-full-size {
  & > .base-spinner__wrapper {
    position: fixed;
  }
}

._spinner-no-overlay {
  .base-spinner__wrapper {
    background: none;
  }
}

._spinner-s {
  .base-spinner {
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    &, &:after {
      width: 24px;
      height: 24px;
    }
  }
}

._spinner-xs {
  .base-spinner {
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    &, &:after {
      width: 20px;
      height: 20px;
    }
  }
}

._field-spinner {
  .base-spinner__wrapper {
    top: 20px;
    transform: translateY(-50%);
  }
}

._spinner-right {
  &-5 {
    .base-spinner {
      left: unset;
      right: 5px;
    }
  }
  &-10 {
    .base-spinner {
      left: unset;
      right: 11px;
    }
  }
  &-15 {
    .base-spinner {
      left: unset;
      right: 15px;
    }
  }
  &-20 {
    .base-spinner {
      left: unset;
      right: 20px;
    }
  }
  &-30 {
    .base-spinner {
      left: unset;
      right: 30px;
    }
  }
  &-35 {
    .base-spinner {
      left: unset;
      right: 35px;
    }
  }
  &-40 {
    .base-spinner {
      left: unset;
      right: 40px;
    }
  }
}
