._hover {
  &-opacity {
    transition: .2s ease-out;
    &:hover {
      opacity: .4;
    }
  }
  &-color-accent {
    &, & > * {
      transition: color .2s ease-out;
    }
    &:hover, &:hover > * {
      color: var(--text-color-accent);
    }
  }
  &-border-accent {
    & {
      transition: color .2s ease-out;
    }
    &:hover {
      border-color: var(--text-color-accent);
    }
  }
}
