._vh {
  &-60 {
    max-height: 60vh;
  }
  &-70 {
    max-height: 70vh;
  }
  &-80 {
    max-height: 80vh;
  }
  &-85 {
    max-height: 85vh;
  }
}

._w {
  &-25 {
    width: 25%;
  }
  &-30 {
    width: 30%;
  }
  &-33 {
    width: 33%;
  }
  &-35 {
    width: 35%;
  }
  &-40 {
    width: 40%;
  }
  &-45 {
    width: 45%;
  }
  &-50 {
    width: 50%;
  }
  &-55 {
    width: 55%;
  }
  &-60 {
    width: 60%;
  }
  &-65 {
    width: 65%;
  }
  &-70 {
    width: 70%;
  }
  &-80 {
    width: 80%;
  }
  &-auto {
   width: auto;
 }
  &-56px {
    width: 56px;
  }
  &-120px {
    width: 120px;
  }
}

._h {
  &-auto {
    height: auto;
  }
  &-22px {
    height: 22px;
  }
  &-56px {
    height: 56px;
  }
  &-120px {
    height: 120px;
  }
  &-400px {
    height: 400px;
  }
}

._full-size {
  &-42 {
    height: 42px;
    width: 42px;
    min-width: 42px;
    min-height: 42px;
  }
}

._mw {
  &-18 {
    max-width: 18%;
  }
  &-25 {
    max-width: 25%;
  }
  &-33 {
    max-width: 33%;
  }
  &-50 {
    max-width: 50%;
  }
  &-90 {
    max-width: 90%;
  }
  &-100 {
    max-width: 100%;
  }
  &-3xl {
    max-width: 1195px;
  }
  &-xxl {
    max-width: 700px;
  }
  &-xl {
    max-width: 600px;
  }
  &-mxx {
    max-width: 550px;
  }
  &-mm {
    max-width: 460px;
  }
  &-mx {
    max-width: 400px;
  }
  &-2md {
    max-width: 352px;
  }
  &-md {
    max-width: 340px;
  }
  &-ml {
    max-width: 300px;
  }
  &-2ms {
    max-width: 250px;
  }
  &-ms {
    max-width: 220px;
  }
  &-m {
    max-width: 200px;
  }
  &-sm {
    max-width: 170px;
  }
  &-s {
    max-width: 150px;
  }
  &-xs {
    max-width: 140px;
  }
  &-xxss {
    max-width: 120px;
  }
  &-xss {
    max-width: 110px;
  }
  &-xxs {
    max-width: 100px;
  }
  &-xsm {
    max-width: 90px;
  }
  &-xxxss {
    max-width: 80px;
  }
  &-xxxs {
    max-width: 75px;
  }
  &-xxxxs {
    max-width: 56px;
  }
  &-xxxxxss {
    max-width: 49px;
  }
  &-xxxxss {
    max-width: 32px;
  }
  &-696px {
    max-width: 696px;
  }
  &-700px {
    max-width: 700px;
  }
}

._max-height {
  &-64 {
    max-height: 64px;
  }
  &-180 {
    max-height: 180px;
  }
  &-200 {
    max-height: 200px;
  }
  &-524 {
    max-height: 524px;
  }
}

._min-height {
  &-42 {
    min-height: 42px;
  }
  &-62 {
    min-height: 62px;
  }
  &-104 {
    min-height: 104px;
  }
  &-160 {
    min-height: 160px;
  }
  &-223 {
    min-height: 223px;
  }
  &-264 {
    min-height: 264px;
  }
  &-280 {
    min-height: 280px;
  }
  &-380 {
    min-height: 380px;
  }
  &-524 {
    min-height: 524px;
  }
}

._min-width,
.mat-mdc-button._min-width, .mat-mdc-unelevated-button._min-width, .mat-mdc-raised-button._min-width, .mat-mdc-fab._min-width, .mat-mdc-mini-fab._min-width, .mat-mdc-icon-button._min-width, .mat-mdc-link-btn._min-width {
  &-100 {
    min-width: 100%;
  }
  &-25 {
    min-width: 25%;
  }
  &-m {
    min-width: 150px;
  }
  &-3s {
    min-width: 140px;
  }
  &-xxxxss {
    min-width: 32px;
  }
  &-120px {
    min-width: 120px;
  }
  &-133px {
    min-width: 133px;
  }
  &-145px {
    min-width: 145px;
  }
  &-160px {
    min-width: 160px;
  }
  &-180px {
    min-width: 180px;
  }
}

._min-max-w {
  &-23 {
    min-width: 23%;
    max-width: 23%;
  }
  &-25 {
    min-width: 25%;
    max-width: 25%;
  }
  &-32 {
    min-width: 32%;
    max-width: 32%;
  }
  &-346px { // todo: check choices view
    min-width: 346px;
    max-width: 346px;
  }
}

._full-width {
  width: 100%;
}

._full-height {
  height: 100%;
}

._full-size-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
