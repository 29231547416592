.mat-mdc-flat-button .mat-mdc-icon-button,
.mat-mdc-button > .mat-icon,
.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon,
.mat-mdc-icon-button,
.mat-icon {
  padding: 0;
  vertical-align: middle;
  color: var(--icon-color-primary);
  svg, img {
    width: 100% !important;
    height: 100% !important;
  }
  .sub-path {
    fill: var(--icon-color-primary-light);
  }
  &._icon {
    &-size {
      &-s {
        width: 20px;
        height: 20px;
        line-height: 20px;
      }
      &-xs {
        width: 18px;
        height: 18px;
        line-height: 18px;
      }
      &-2xs {
        width: 16px;
        height: 16px;
        line-height: 16px;
      }
      &-3xs {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;
        line-height: 12px;
      }
      &-4xs {
        width: 10px;
        height: 10px;
        line-height: 10px;
      }
      &-5xs {
        width: 8px;
        height: 8px;
        line-height: 8px;
      }
    }
    &-alt {
      color: var(--color-white);
      .sub-path {
        fill: var(--icon-color-primary-light);
      }
    }
    &-secondary {
      color: var(--icon-color-secondary);
      .sub-path {
        fill: var(--icon-color-secondary-light);
      }
    }
    &-primary-dark {
      color: var(--icon-color-primary-dark);
      .sub-path {
        fill: var(--icon-color-primary-dark);
      }
    }
    &-error {
      color: var(--icon-color-warning);
      .sub-path {
        fill: var(--icon-color-warning-light);
      }
    }
    &-success {
      color: var(--icon-color-success);
      .sub-path {
        fill: var(--icon-color-success);
      }
    }
    &-success-label {
      color: var(--icon-color-success-label);
      .sub-path {
        fill: var(--icon-color-success-label);
      }
    }
  }
}
