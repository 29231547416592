.custom-modal {
  overflow: hidden;
  height: 100%;
  &__content {
    display: flex;
    padding: 20px;
    overflow-y: auto;
    flex-direction: column;
    background-color: var(--modal-bg-color);
    height: calc(100% - var(--modal-header-height));
    &._row {
      flex-direction: row;
    }
    &._alt-bg {
      background-color: var(--color-secondary-thin);
    }
    &._p {
      &-0 {
        padding: 0;
      }
      &v {
        &-36 {
          padding: 36px 0;
        }
      }
    }
  }
}
