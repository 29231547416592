.mat-mdc-menu-trigger {
  .mdc-button__label {
    font-weight: 500;
    font-size: var(--font-size-m);
    color: var(--text-color-primary);
    .mat-icon {
      color: #71717A;
    }
  }
}

.mat-mdc-menu-panel {
  min-height: auto !important;
  .mat-mdc-menu-content {
    padding: 0;
    line-height: normal;
    font-weight: inherit;
    letter-spacing: normal;
    font-size: var(--font-size-ssm);
    font-family: var(--font-family-poppins);
    .mat-mdc-menu-item {
      height: 40px;
      min-height: 40px;
      .mat-mdc-menu-item-text {
        font-weight: 600;
        line-height: 40px;
        letter-spacing: unset;
        font-size: var(--font-size-ssm);
        color: var(--text-color-primary);
        font-family: var(--font-family-poppins);
      }
    }
  }
}
