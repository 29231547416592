.custom-form {
  &._disabled {
    .custom-form__item-title, .custom-form__item-field-icon.mat-icon {
      opacity: .5;
      color: var(--field--disabled-text-color);
    }
  }
  &._form-field-white .mdc-notched-outline .mdc-notched-outline {
    &__leading, &__notch, &__trailing {
      background-color: var(--color-white);
    }
  }
  &._form-title-secondary {
    .custom-form__item-title {
      color: var(--field-title-text-color-secondary);
    }
  }
  &__title {
    margin-bottom: 38px;
  }
  &__body {
    &-error {
      font-weight: 500;
      color: var(--field-border-error-color);
      font-size: var(--font-size-ssm);
    }
    &._l-space {
      .custom-form__item:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }
  &__combine-items {
    display: flex;
    margin: 0 -6px;
    dropdown {
      width: 100%;
    }
    .custom-form__item {
      width: 100%;
      padding: 0 6px;
      box-sizing: border-box;
      &._pl-0 {
        padding-left: 0;
      }
      &._pr-24 {
        padding-right: 2.4rem;
      }
      &._as-selector {
        padding: 0;
      }
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
    &:not(:last-child) {
      margin-bottom: var(--base-item-margin);
    }
    &._xxs-space:not(:last-child) {
      margin-bottom: 4px;
    }
    &._m-space:not(:last-child) {
      margin-bottom: 24px;
    }
    &._lg-space:not(:last-child) {
      margin-bottom: 30px;
    }
    &._50 {
      .custom-form__item {
        width: 50%;
        box-sizing: border-box;
      }
    }
    &._33 {
      .custom-form__item {
        width: 33%;
        box-sizing: border-box;
      }
    }
  }
  &__item {
    display: flex;
    position: relative;
    flex-direction: column;
    &._d-n {
      display: none;
    }
    &._d-f {
      display: flex;
    }
    &._row {
      flex-direction: row;
    }
    &._h-m {
      .mat-mdc-form-field-type-mat-select .mat-mdc-form-field-infix {
        padding: 9px 0 7px 0;
     }
    }
    &-error {
      &-wrapper {
        padding-top: 4px;
      }
      line-height: 1;
      font-size: 10px;
      font-weight: 600;
      padding: 6px 12px;
      position: relative;
      letter-spacing: .4px;
      display: inline-block;
      transition: 500ms ease-out;
      color: var(--color-warning);
      border-radius: var(--base-border-radius);
      background-color: var(--color-warning-secondary);
      &:before {
        width: 0;
        height: 0;
        z-index: 1;
        top: -9px;
        left: 12px;
        content: '';
        position: absolute;
        transition: 500ms ease-out;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid var(--color-warning-secondary);
      }
    }
    &._ib {
      display: inline-block;
      .custom-form__item-title {
        display: block;
      }
    }
    &._clear-btn {
      &.mat-mdc-form-field .mat-mdc-text-field-wrapper {
        padding: 0 22px 0 12px !important;
      }
    }
    &._readonly, &._disabled .mdc-notched-outline .mdc-notched-outline {
      &__leading, &__notch, &__trailing {
        background-color: var(--field-disabled-bg);
      }
    }
    &:not(:last-child) {
      margin-bottom: var(--base-item-margin);
    }
    &._m-space:not(:last-child) {
      margin-bottom: 24px;
    }
    &-title {
      margin-bottom: 3px;
      font-weight: 600;
      font-size: var(--font-size-ssm);
      color: var(--field-title-text-color);
      letter-spacing: .1px;
      font-family: var(--font-family-poppins);
      &._required {
        position: relative;
        &:after {
          top: -2px;
          content: "*";
          padding-left: 2px;
          position: absolute;
        }
      }
    }
    &-field {
      position: relative;
      &._textarea {
        .mat-mdc-form-field-infix {
          padding: 1.35rem 0 1.15rem 0;
        }
      }
      &._custom-selected-value {
        .mat-mdc-form-field-infix {
          padding: 0.59rem 0 0.304rem 0;
        }
      }
      &.mat-form-field-invalid._static-error {
        .mat-mdc-form-field-subscript-wrapper {
          top: 0;
          position: static;
        }
        .mat-mdc-form-field-error {
          margin-top: 4px;
          &-wrapper {
            position: static;
          }
        }
        &._full-width-error {
          .mat-mdc-form-field-subscript-wrapper {
            display: flex;
            white-space: nowrap;
            overflow: unset;
          }
        }
      }
      &._preselected {
        pointer-events: none;
      }
      &._icon-field {
        &.mat-mdc-form-field .mat-mdc-text-field-wrapper {
          padding: 0 28px 0 12px !important;
        }
      }
      &-icon.mat-icon {
        top: 50%;
        left: 100%;
        width: 18px;
        height: 18px;
        line-height: 18px;
        position: absolute;
        transform: translateY(-50%);
        color: var(--icon-color-secondary);
      }
    }
  }
  &__actions {
    margin-top: 32px;
  }
  &__autocomplete-paginator.mat-mdc-autocomplete-panel {
    min-width: 330px;
    max-width: 600px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &__select-paginator {
    &-base-wrapper {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
    &-wrapper {
      min-width: 330px;
      display: flex;
      position: fixed;
      background-color: var(--color-white);
      z-index: 1;
      height: 30px;
      line-height: 30px;
      box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
      &._disable {
        &:before {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          content: '';
          background-color: var(--color-white);
          opacity: .5;
        }
      }
    }
  }
  &__clear-btn.mat-mdc-icon-button {
    top: 50%;
    right: -32px;
    position: absolute;
    transform: translateY(-50%);
    &.mat-mdc-icon-button mat-icon {
      width: 14px;
      height: 14px;
      font-size: 14px;
      line-height: 14px;
      color: var(--icon-color-secondary);
    }
  }
 &__autocomplete-wrapper.mat-mdc-autocomplete-panel {
    max-width: 60vw;
    .mat-mdc-option {
      overflow: unset;
    }
  }
}
