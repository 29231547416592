._background {
  &-white {
    background-color: var(--color-white);
  }
  &-primary-light {
    background-color: var(--color-primary-light);
  }
  &-secondary-thin {
    background-color: var(--color-secondary-thin);
  }
  &-secondary-light {
    background-color: var(--color-secondary-light);
  }
  &-no-image {
    background-color: var(--no-img-bg);
  }
}
