:root {
  // base colors
  --color-white: #FFFFFF;
  --color-black: #000000;

  // app colors
  --color-primary: #0088F7;
  --color-primary-dark: #1364B6;
  --color-primary-light: #E8F2FC;
  --color-primary-medium: #BAD8F7;

  --color-secondary: #828D96;
  --color-secondary-dark: #3F4254;
  --color-secondary-thin: #F6F8FA;
  --color-secondary-light: #E8ECF2;
  --color-secondary-mediym: #D2DAE1;

  --color-warning: #DC2626;
  --color-warning-secondary: #FBE9E9;

  --color-note: #d4703f;
  --color-note-secondary: #FFF9E9;

  --color-success: #1BC5BD;
  --color-success-v1: #D5DB95;
  --color-success-dark: #139F99;
  --color-success-thin: #DAEEED;
  --color-success-light: #C9F7F5;
  --color-success-mediym: #79D8D3;

  // text colors
  --text-color-primary: #3F4254;
  --text-color-primary-dark: #001A35;
  --text-color-secondary: #828D96;
  --text-color-accent: #0088F7;
  --text-color-accent-light: #2C88A9;

  // border colors
  --border-color-primary: #D2DAE1;
  --border-color-primary-dark: #8B93A3;
  --border-color-secondary: #E8ECF2;
  --border-color-success: #3B8A59;

  // box shadows
  --box-shadow-primary: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
  --box-shadow-primary-alt: 0px -0.5px 4px -1px rgba(0, 0, 0, 0.25);
  --box-shadow-base: 0px 1px 2px #D2DAE1, 0px 1px 3px #E8ECF2;
  --box-shadow-secondary: 0px 2px 4px #D2DAE1, 0px 4px 6px #E8ECF2;

  // img
  --no-img-bg: #f2f2f2;

  // icons
  --icon-color-primary: #0088F7;
  --icon-color-primary-light: #BAD8F7;
  --icon-color-primary-dark: #001A35;
  --icon-color-secondary: #828D96;
  --icon-color-secondary-dark: #D2DAE1;
  --icon-color-secondary-light: #E8ECF2;
  --icon-color-success: #3B8A59;
  --icon-color-success-label: var(--color-success);
  --icon-color-warning: var(--color-warning);
  --icon-color-warning-light: var(--color-warning-secondary);

  // app backgrounds
  --auth-primary-bg: #ffffff;
  --auth-secondary-bg: linear-gradient(-45deg, #2799f6, #3270a3);

  --primary-light-bg: #F7F8F9;

  // buttons
  --btn-height-m: 40px;
  --btn-height-s: 30px;

  // button primary
  --btn-primary-bg: #0088F7;
  --btn-primary-text: #FFFFFF;
  --btn-primary-hover-bg: #1364B6;
  --btn-primary-disable-bg: #D2DAE1;

  // button primary dark
  --btn-primary-dark-bg: #001A35;
  --btn-primary-dark-text: #FFFFFF;
  --btn-primary-dark-hover-bg: #001A35;
  --btn-primary-dark-disable-bg: #DDDFE3;

  // button secondary
  --btn-secondary-bg: #ffffff;
  --btn-secondary-border: #0088F7;
  --btn-secondary-text: #0088F7;
  --btn-secondary-hover-bg: #E8F2FC;
  --btn-secondary-disable-border: #BAD8F7;
  --btn-secondary-disable-text: #BAD8F7;

  // button alternative
  --btn-alt-bg: #1BC5BD;
  --btn-alt-text: #FFFFFF;
  --btn-alt-hover-bg: #139F99;
  --btn-alt-disable-bg: #79D8D3;

  // button alternative dark
  --btn-alt-dark-bg: #3B8A59;
  --btn-alt-dark-text: #FFFFFF;
  --btn-alt-dark-hover-bg: #3B8A59;
  --btn-alt-dark-disable-bg: #DDDFE3;

  // button clean
  --btn-clean-bg: trasparent;
  --btn-clean-text: #001A35;
  --btn-clean-hover-bg: trasparent;
  --btn-clean-disable-bg: trasparent;

  // button collapse
  --btn-collapse-bg: #F7F8F9;
  --btn-collapse-text: #001A35;;
  --btn-collapse-hover-bg: #F7F8F9;
  --btn-collapse-disable-bg: #DDDFE3;

  // button warn
  --btn-warn-bg: trasparent;
  --btn-warn-text: #B63B2A;
  --btn-warn-hover-bg: trasparent;
  --btn-warn-disable-bg: trasparent;

  // button warn colored
  --btn-warn-colored-bg: #B63B2A;
  --btn-warn-colored-text: #FFFFFF;
  --btn-warn-colored-hover-bg: #B63B2A;
  --btn-warn-colored-disable-bg: #DDDFE3;

  // button icon primary
  --btn-icon-primary-bg: #E8F2FC;

  // button icon warn
  --btn-icon-warn-bg: var(--color-warning-secondary);
  --btn-icon-warn-svg: var(--color-warning);

  // button link
  --btn-link-text: #0088F7;
  --btn-link-hover-text: #1364B6;

  // modals
  --modal-header-height: 50px;
  --modal-bg-color: #FFFFFF;

  // forms
  --field-border-color: #D2DAE1;
  --field-border-active-color: #0088F7;
  --field-border-error-color: #DC2626;
  --field-disabled-bg: #E8ECF2;
  --field-placeholder-color: #828D96;
  --field-text-color: #3F4254;
  --field--disabled-text-color: rgba(0, 0, 0, 0.38);
  --field-title-text-color: #3F4254;
  --field-title-text-color-secondary: #828D96;
  --option-text-color: #828D96;
  --selected-option-bg: #F6F8FA;

  --base-item-margin: 14px;

  // table
  --table-actions-height: 58px;
  --table-header-height: 40px;
  --table-row-hover: #F6F8FA;
  --table-sort-icon-color: #D2DAE1;
  --table-border-color-primary: #E8ECF2;
  --table-border-color-secondary: #D2DAE1;

  // checkbox | radiobutton | toggle
  --toggle-items-primary-color: #0088F7;
  --toggle-items-primary-dark-color: #001A35;

  --toggle-items-secondary-color: #D2DAE1;

  // border-radius
  --base-border-radius: 4px;

  // app header
  --app-header-height: 50px;

  // app sidenav
  --app-sidenav-width: 220px;
  --app-sidenav-collapsed-width: 37px;

  // app space
  --app-page-vertical-space: 20px;
  --app-page-horizontal-space: 25px;

  // auth
  --base-auth-body-width: 375px;

  // create order
  --create-order-width: 900px;

  // view order
  --view-order-width: 900px;

  // logo colors
  --short-logo-color: #5899af;

  // support chat
  --order-notes-bg: #ffffff;
  --order-notes-width: 440px;
  --order-notes-height: 100vh;
  --order-notes-border: 1px solid #EBEFF2;
  --order-notes-box-shadow: 0 4px 20px rgb(212 213 217 / 20%);
  --order-notes-message-bg: #E8F2FC;
  --order-notes-answer-bg: #F6F8FA;

  // font sizes
  --font-size-xxxxxxxl: 5rem;
  --font-size-xxxxxxl: 4rem;
  --font-size-xxxxl: 3.4rem;
  --font-size-xxxl: 3rem;
  --font-size-mmxxxl: 2.6rem;
  --font-size-mxxl: 2.5rem;
  --font-size-mmxxl: 2.4rem;
  --font-size-smxxl: 2.3rem;
  --font-size-xxl: 2.2rem;
  --font-size-sxxl: 2.1rem;
  --font-size-xl: 2rem;
  --font-size-ll: 1.8rem;
  --font-size-l: 1.7rem;
  --font-size-lmd: 1.6rem;
  --font-size-md: 1.5rem;
  --font-size-m: 1.4rem;
  --font-size-ssm: 1.3rem;
  --font-size-sm: 1.2rem;
  --font-size-sxm: 1.1rem;
  --font-size-xs: 1rem;

  // font family
  --base-font-family: Roboto, "Helvetica Neue", sans-serif;
  --font-family-poppins: 'DMEPoppins', sans-serif;
  --font-family-jakarta: 'PlusJakartaSans', sans-serif;
}

// media queries
$max-xxs-screen: 374px;
$max-xs-screen: 414px;
$max-xss-screen: 456px;
$max-sm-screen: 565px;
$max-md-screen: 767px;
$max-mmd-screen: 868px;
$max-lg-screen: 991px;
$max-xlg-screen: 1200px;
$max-xllg-screen: 1366px;
$max-xlllg-screen: 1400px;
$max-xxlg-screen: 1440px;
$max-xxxlg-screen: 1680px;

$mobile-version: 1024px;
