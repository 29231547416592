._border {
  &-bottom {
    &-none {
      border-bottom: none;
    }
  }
  &-radius {
    border-radius: var(--base-border-radius);
    &-50 {
      border-radius: 50%;
    }
  }
  &-primary {
    &-full {
      border: 1px solid var(--border-color-primary);
    }
    &-top {
      border-top: 1px solid var(--border-color-primary);
    }
    &-right {
      border-right: 1px solid var(--border-color-primary);
    }
    &-bottom {
      border-bottom: 1px solid var(--border-color-primary);
    }
    &-left {
      border-left: 1px solid var(--border-color-primary);
    }
  }
  &-success {
    &-full {
      border: 1px solid var(--border-color-success);
    }
    &-top {
      border-top: 1px solid var(--border-color-success);
    }
    &-right {
      border-right: 1px solid var(--border-color-success);
    }
    &-bottom {
      border-bottom: 1px solid var(--border-color-success);
    }
    &-left {
      border-left: 1px solid var(--border-color-success);
    }
  }
  &-primary-dark {
    &-full {
      border: 1px solid var(--border-color-primary-dark);
    }
    &-top {
      border-top: 1px solid var(--border-color-primary-dark);
    }
    &-right {
      border-right: 1px solid var(--border-color-primary-dark);
    }
    &-bottom {
      border-bottom: 1px solid var(--border-color-primary-dark);
    }
    &-left {
      border-left: 1px solid var(--border-color-primary-dark);
    }
  }
  &-secondary {
    &-full {
      border: 1px solid var(--border-color-secondary);
    }
    &-top {
      border-top: 1px solid var(--border-color-secondary);
    }
    &-right {
      border-right: 1px solid var(--border-color-secondary);
    }
    &-bottom {
      border-bottom: 1px solid var(--border-color-secondary);
      &-nlc {
        &:not(:last-child) {
          border-bottom: 1px solid var(--border-color-secondary);
        }
      }
    }
    &-left {
      border-left: 1px solid var(--border-color-secondary);
    }
  }
  &-warning {
    &-full {
      border: 1px solid var(--color-warning);
    }
  }
}
