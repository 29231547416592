.mat-mdc-table {
  width: 100%;
  .mat-mdc-header-row {
    height: 40px;
    min-height: 34px;
  }
  .mat-mdc-cell, .mat-mdc-header-cell {
    overflow: unset;
    padding: 3px 6px;
    font-weight: 600;
    vertical-align: middle;
    font-size: var(--font-size-ssm);
    color: var(--text-color-secondary);
    font-family: var(--font-family-poppins);
    border-color: var(--border-color-primary);
  }
  th.mat-mdc-header-cell, td.mat-mdc-cell, td.mat-mdc-footer-cell {
    border-bottom: none;
    letter-spacing: normal;
  }
  tr.mat-mdc-row._border-top-none, tr.mat-mdc-footer-row._border-top-none {
    border-top: none;
  }
  tr.mat-mdc-row, tr.mat-mdc-footer-row {
    border-top: 1px solid var(--border-color-primary);
  }
  th.mat-mdc-header-cell:first-of-type, td.mat-mdc-cell:first-of-type, td.mat-mdc-footer-cell:first-of-type {
    padding-left: 8px;
  }
  th.mat-mdc-header-cell:last-of-type, td.mat-mdc-cell:last-of-type, td.mat-mdc-footer-cell:last-of-type {
    padding-right: 8px;
  }
  tbody {
    border-bottom: 1px solid var(--border-color-primary);
  }
  tr.mat-mdc-row, tr.mat-mdc-footer-row {
    height: 34px;
  }
}
