._direction {
  &-row {
    flex-direction: row;
  }
  &-column {
    flex-direction: column;
  }
}

._flex {
  &-grow {
    &-1 {
      flex-grow: 1;
    }
  }
  &-shrink {
    &-0 {
      flex-shrink: 0;
    }
  }
  &-wrap {
    flex-wrap: wrap;
  }
  &-no-wrap {
    flex-wrap: nowrap;
  }
}

._self {
  &-start {
    align-self: flex-start;
  }
  &-end {
    align-self: flex-end;
  }
}

._align {
  &-start {
    align-items: flex-start;
  }
  &-center {
    align-items: center;
  }
  &-end {
    align-items: flex-end;
  }
}

._align-content {
  &-start {
    align-content: flex-start;
  }
}

._align-self {
  &-start {
    align-self: flex-start;
  }
  &-center {
    align-self: center;
  }
  &-end {
    align-self: flex-end;
  }
  &-stretch {
    align-self: stretch;
  }
}

._justify {
  &-start {
    justify-content: flex-start;
  }
  &-center {
    justify-content: center;
  }
  &-end {
    justify-content: flex-end;
  }
  &-between {
    justify-content: space-between;
  }
  &-around {
    justify-content: space-around;
  }
}

._align-justify {
  &-center {
    align-items: center;
    justify-content: center;
  }
}
