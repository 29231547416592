.mat-mdc-unelevated-button, .mat-mdc-raised-button, .mat-mdc-fab, .mat-mdc-mini-fab {
  &.mat-mdc-button-base {
    height: 40px;
    font-weight: 500;
    white-space: nowrap;
    letter-spacing: normal;
    font-size: var(--font-size-ssm);
    font-family: var(--font-family-poppins);
    transition: background-color .2s ease-in-out !important;
    border-radius: var(--mdc-filled-button-container-shape);
    padding: 0 var(--mat-filled-button-horizontal-padding, 16px);
    text-transform: var(--mdc-filled-button-label-text-transform);
    .mat-mdc-button-touch-target {
      height: inherit;
    }
  }
  & > .mat-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    --mat-filled-button-icon-offset: 0;
    --mat-filled-button-icon-spacing: 0;
  }
  .mdc-button__label {
    height: 100%;
    display: flex;
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    justify-content: center;
  }
  &._no-animation {
    transition: none !important;
  }
  &._hidden {
    display: none;
  }
  &._btn {
    &-custom-space {
      padding: 0 16px 0 10px;
      &-0 {
        padding: 0;
      }
    }
    &-size {
      &-s {
        height: 30px;
        line-height: 30px;
        .mat-icon {
          width: 16px;
          height: 16px;
          line-height: 16px;
        }
      }
    }
  }
}

.mat-mdc-button-disabled {
  opacity: .5;
  & > .mat-icon {
    opacity: .5;
  }
}

.mat-mdc-button.mat-mdc-button-base {
  letter-spacing: normal;
}

.mat-mdc-unelevated-button.mat-primary, .mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary.cdk-focused, .mat-mdc-raised-button.mat-primary.cdk-focused {
  &.mat-mdc-button-base {
    color: var(--btn-primary-text);
    background-color: var(--btn-primary-bg);
  }
  .mat-mdc-focus-indicator {
    opacity: 0;
  }
  &.mat-mdc-button-disabled {
    color: var(--btn-primary-text);
    background-color: var(--btn-primary-disable-bg);
    &:hover {
      background-color: var(--btn-primary-disable-bg);
    }
  }
  &:hover {
    background-color: var(--btn-primary-hover-bg);
  }
}

.mat-mdc-unelevated-button.mat-primary-dark, .mat-mdc-raised-button.mat-primary-dark, .mat-mdc-unelevated-button.mat-primary-dark.cdk-focused, .mat-mdc-raised-button.mat-primary-dark.cdk-focused {
  &.mat-mdc-button-base {
    color: var(--btn-primary-text);
    background-color: var(--btn-primary-dark-bg);
  }
  .mat-mdc-focus-indicator {
    opacity: 0;
  }
  &.mat-mdc-button-disabled {
    color: var(--btn-primary-dark-text);
    background-color: var(--btn-primary-dark-disable-bg);
    &:hover {
      background-color: var(--btn-primary-dark-disable-bg);
    }
  }
  &:hover {
    background-color: var(--btn-primary-dark-hover-bg);
  }
}

.mat-mdc-unelevated-button.mat-secondary, .mat-mdc-raised-button.mat-secondary, .mat-mdc-unelevated-button.mat-secondary.cdk-focused, .mat-mdc-raised-button.mat-secondary.cdk-focused {
  &.mat-mdc-button-base {
    font-weight: 600;
    color: var(--btn-secondary-text);
    background-color: var(--btn-secondary-bg);
    border: 1px solid var(--btn-secondary-border);
  }
  .mat-mdc-focus-indicator {
    opacity: 0;
  }
  &.mat-mdc-button-disabled {
    color: var(--btn-secondary-disable-text);
    background-color: var(--btn-secondary-bg);
    border: 1px solid var(--btn-secondary-disable-border);
    &:hover {
      background-color: var(--btn-secondary-bg);
    }
  }
  &:hover {
    background-color: var(--btn-secondary-hover-bg);
  }
}

.mat-mdc-unelevated-button.mat-secondary-alt, .mat-mdc-raised-button.mat-secondary-alt, .mat-flat-button.mat-secondary-alt.cdk-focused, .mat-raised-button.mat-secondary-alt.cdk-focused {
  &.mat-mdc-button-base {
    font-weight: 600;
    color: var(--btn-secondary-text);
    background-color: var(--btn-secondary-bg);
  }
  .mat-mdc-focus-indicator {
    opacity: 0;
  }
  &.mat-mdc-button-disabled {
    color: var(--btn-secondary-disable-text);
    background-color: var(--btn-secondary-bg);
    &:hover {
      background-color: var(--btn-secondary-bg);
    }
  }
  &:hover {
    background-color: var(--btn-secondary-bg);
  }
}

.mat-mdc-unelevated-button.mat-alternative, .mat-mdc-raised-button.mat-alternative, .mat-mdc-unelevated-button.mat-alternative.cdk-focused, .mat-mdc-raised-button.mat-alternative.cdk-focused {
  &.mat-mdc-button-base {
    color: var(--btn-alt-text);
    background-color: var(--btn-alt-bg);
  }
  .mat-mdc-focus-indicator {
    opacity: 0;
  }
  &.mat-mdc-button-disabled {
    color: var(--btn-alt-text);
    background-color: var(--btn-alt-disable-bg);
    &:hover {
      background-color: var(--btn-alt-disable-bg);
    }
  }
  &:hover {
    background-color: var(--btn-alt-hover-bg);
  }
}

.mat-mdc-unelevated-button.mat-alternative-dark, .mat-mdc-raised-button.mat-alternative-dark, .mat-mdc-unelevated-button.mat-alternative-dark.cdk-focused, .mat-mdc-raised-button.mat-alternative-dark.cdk-focused {
  &.mat-mdc-button-base {
    color: var(--btn-alt-dark-text);
    background-color: var(--btn-alt-dark-bg);
  }
  .mat-mdc-focus-indicator {
    opacity: 0;
  }
  &.mat-mdc-button-disabled {
    color: var(--btn-alt-dark-text);
    background-color: var(--btn-alt-dark-disable-bg);
    &:hover {
      background-color: var(--btn-alt-dark-disable-bg);
    }
  }
  &:hover {
    background-color: var(--btn-alt-dark-hover-bg);
  }
}

.mat-mdc-unelevated-button.mat-clean, .mat-mdc-raised-button.mat-clean, .mat-mdc-unelevated-button.mat-clean.cdk-focused, .mat-mdc-raised-button.mat-clean.cdk-focused {
  &.mat-mdc-button-base {
    color: var(--btn-clean-text);
    background-color: var(--btn-clean-bg);
  }
  .mat-mdc-focus-indicator {
    opacity: 0;
  }
  &.mat-mdc-button-disabled {
    color: var(--btn-clean-text);
    background-color: var(--btn-clean-disable-bg);
    &:hover {
      background-color: var(--btn-clean-disable-bg);
    }
  }
  &:hover {
    background-color: var(--btn-clean-hover-bg);
  }
}

.mat-mdc-unelevated-button.mat-collapse, .mat-mdc-raised-button.mat-collapse, .mat-mdc-unelevated-button.mat-collapse.cdk-focused, .mat-mdc-raised-button.mat-collapse.cdk-focused {
  &.mat-mdc-button-base {
    color: var(--btn-collapse-text);
    background-color: var(--btn-collapse-bg);
  }
  .mat-mdc-focus-indicator {
    opacity: 0;
  }
  &.mat-mdc-button-disabled {
    color: var(--btn-collapse-text);
    background-color: var(--btn-collapse-disable-bg);
    &:hover {
      background-color: var(--btn-collapse-disable-bg);
    }
  }
  &:hover {
    background-color: var(--btn-collapse-hover-bg);
  }
}

.mat-mdc-unelevated-button.mat-warn, .mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn.cdk-focused, .mat-mdc-raised-button.mat-warn.cdk-focused {
  &.mat-mdc-button-base {
    color: var(--btn-warn-text);
    background-color: var(--btn-warn-bg);
  }
  .mdc-button__label {
    font-weight: 600;
  }
  .mat-mdc-focus-indicator {
    opacity: 0;
  }
  &.mat-mdc-button-disabled {
    color: var(--btn-warn-text);
    background-color: var(--btn-warn-disable-bg);
    &:hover {
      background-color: var(--btn-warn-disable-bg);
    }
  }
  &:hover {
    background-color: var(--btn-warn-hover-bg);
  }
}

.mat-mdc-unelevated-button.mat-warn-colored, .mat-mdc-raised-button.mat-warn-colored, .mat-mdc-unelevated-button.mat-warn-colored.cdk-focused, .mat-mdc-raised-button.mat-warn-colored.cdk-focused {
  &.mat-mdc-button-base {
    color: var(--btn-warn-colored-text);
    background-color: var(--btn-warn-colored-bg);
  }
  .mat-mdc-focus-indicator {
    opacity: 0;
  }
  &.mat-mdc-button-disabled {
    color: var(--btn-warn-colored-text);
    background-color: var(--btn-warn-colored-disable-bg);
    &:hover {
      background-color: var(--btn-warn-colored-disable-bg);
    }
  }
  &:hover {
    background-color: var(--btn-warn-colored-hover-bg);
  }
}

.mat-mdc-unelevated-button.mat-link-btn, .mat-mdc-raised-button.mat-link-btn, .mat-mdc-unelevated-button.mat-link-btn.cdk-focused, .mat-mdc-raised-button.mat-link-btn.cdk-focused {
  &.mat-mdc-button-base {
    padding: 0;
    border: none;
    height: auto;
    line-height: 1;
    min-width: auto;
    font-weight: 700;
    text-decoration: underline;
    color: var(--btn-link-text) ;
    background-color: transparent;
    font-size: var(--font-size-ssm);
    transition: color .2s ease-in-out !important;
  }
  .mat-mdc-focus-indicator {
    opacity: 0;
  }
  .mat-mdc-button-persistent-ripple.mdc-button__ripple, .mat-mdc-focus-indicator {
    display: none;
  }
  &:hover {
    color: var(--btn-link-hover-text) !important;
  }
  &._no-underline {
    text-decoration: none;
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0;
  width: 40px;
  font-size: 0;
  height: 40px;
  .mat-mdc-button-touch-target {
    width: 40px;
    height: 40px;
  }
  .mat-mdc-button-persistent-ripple {
    display: none;
  }
  &.mat-primary,
  &.mat-primary-alt {
    background-color: var(--btn-icon-primary-bg);
  }
  &.mat-secondary {
    background-color: transparent;
  }
  &.mat-warning {
    background-color: var(--btn-icon-warn-bg);
    .mat-icon {
      color: var(--btn-icon-warn-svg);
    }
  }
  &.mat-primary, &.mat-secondary, &.mat-primary-alt, &.mat-base, &.mat-warning {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: var(--base-border-radius);
    .mat-mdc-button-touch-target {
      width: 30px;
      height: 30px;
    }
    .mat-icon {
      width: 16px;
      height: 16px;
      line-height: 16px;
    }
    &._btn-icon-size {
      &-2s {
        width: 22px;
        height: 22px;
        line-height: 22px;
        .mat-icon {
          width: 14px;
          height: 14px;
          line-height: 14px;
        }
      }
      &-s {
        width: 20px;
        height: 20px;
        line-height: 20px;
      }
    }
  }
}

.mat-mdc-button, .mat-mdc-unelevated-button, .mat-mdc-raised-button, .mat-mdc-fab, .mat-mdc-mini-fab, .mat-mdc-icon-button, .mat-mdc-link-btn {
  &._hidden-visibility {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}

