html {
  font-size: 62.5%;
}

html,body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  font-family: var(--font-family-poppins);
}

body {
  width: 100%;
  font-weight: normal;
  font-size: var(--font-size-m);
  font-family: var(--font-family-poppins);
  h1, h2, h3, h4, h5, h6,
  & > * {
    font-family: var(--font-family-poppins);
  }
}

h1 {
  font-size: var(--font-size-mmxxxl);
  line-height: 1;
  letter-spacing: .2px;
}

h2 {
  font-size: var(--font-size-mmxxl);
  line-height: 1;
  letter-spacing: .2px;
}

h3 {
  font-size: var(--font-size-xl);
  line-height: 1;
  letter-spacing: .2px;
}

h4 {
  font-size: var(--font-size-ll);
  line-height: 1;
  letter-spacing: .2px;
}

h5 {
  font-size: var(--font-size-lmd);
  line-height: 1;
  letter-spacing: .2px;
}

h6 {
  font-size: var(--font-size-m);
  line-height: 1;
  letter-spacing: .2px;
}

.base-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    color: var(--text-color-primary);
    &-icon.mat-icon {
      margin-right: 16px;
      width: 20px;
      color: var(--icon-color-primary);
    }
    &-action.mat-mdc-icon-button {
      padding-top: 2px;
      margin-left: 4px;
    }
    &-action.mat-mdc-icon-button, &-action-icon.mat-icon {
      width: 18px;
      height: 18px;
      line-height: 18px;
      color: var(--icon-color-secondary);
    }
  }
  &__content-item {
    box-shadow: var(--box-shadow-primary);
    background-color: var(--color-white);
    border-radius: var(--base-border-radius);
    &._it-space-24 {
      .base-page__content-item-title,
      .base-page__content-item-body {
        padding: 0 24px;
      }
    }
    &-title {
      padding: 0 12px;
      line-height: 50px;
      color: var(--text-color-primary);
      border-bottom: 1px solid var(--border-color-secondary);
      & > h1, h2,h3, h4, h5, h6 {
        line-height: 50px;
      }
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &._no-margin {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
    &._full-content-size {
      flex-grow: 1;
      overflow: hidden;
    }
    &-body {
      padding: 0 12px;
    }
  }
}

.base-content-padding {
  padding: 20px 22px;
}

.base-content-border-bottom {
  border-bottom: 1px solid var(--border-color-secondary);
}

.mat-calendar-body-cell { // fix first line mat-calendar size
  max-height: 40px;
}

