mat-form-field.mat-mdc-form-field {
  text-align: left;
  font-weight: 600;
  line-height: 1.125;
  position: relative;
  display: inline-block;
  letter-spacing: normal;
  color: var(--field-text-color);
  font-size: var(--font-size-ssm);
  font-family: var(--font-family-poppins);
}

.mat-mdc-form-field {
  &._triangle-error {
    &-bottom {
      &-21:before {
        top: unset;
        bottom: 21px;
      }
    }
  }
  &:before {
    width: 0;
    height: 0;
    top: 36px;
    z-index: 2;
    opacity: 0;
    left: 12px;
    content: '';
    position: absolute;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid var(--color-warning-secondary);
  }
  &.mat-form-field-invalid:before {
    opacity: 1;
    transition: opacity 500ms ease-out;
  }
  ::placeholder {
    font-style: normal;
    font-size: var(--font-size-ssm);
    font-family: var(--font-family-poppins);
    --mdc-outlined-text-field-input-text-placeholder-color: var(--field-placeholder-color);
  }
  &._no-triangle-error.mat-form-field-invalid:before {
    opacity: 0;
    border: none;
  }
  &._invalid-field {
    &.mat-form-field-appearance-outline {
      .mdc-text-field {
        &--outlined {
          &:not(.mdc-text-field--disabled) {
            .mdc-notched-outline {
              &__leading, &__notch, &__trailing {
                border-color: var(--field-border-error-color);
              }
            }
          }
        }
      }
    }
  }
  .mat-mdc-text-field-wrapper {
      padding: 0 12px;
  }
  .mdc-text-field {
    &--outlined, &--no-label {
      .mat-mdc-form-field-infix {
        z-index: 1;
        width: auto;
        min-height: 40px;
        line-height: 13px;
        padding: 1.1rem 0 0.9rem 0;
      }
    }
    &--outlined {
      &.mdc-text-field--disabled .mdc-text-field__input {
        opacity: .5;
        color: var(--field--disabled-text-color) // input disabled text color
      }
      &:not(.mdc-text-field--disabled) {
        .mdc-text-field__input {
          color: var(--field-text-color) // input text color
        }
        .mdc-notched-outline {
          &__leading, &__notch, &__trailing {
            border-color: var(--field-border-color); // border color
          }
        }
        &:not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline {
            &__leading, &__notch, &__trailing {
              border-color: var(--field-border-color); // hover border color
            }
        }
        &.mdc-text-field--focused .mdc-notched-outline .mdc-notched-outline {
          &__leading, &__notch, &__trailing {
            border-width: 1px; // active border width
            border-color: var(--field-border-active-color); // active border color
          }
        }
      }
    }
    &.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline {
      &__leading, &__notch, &__trailing {
          border-color: var(--field-border-error-color); // invalid border color
        }
    }
  }
  .mat-mdc-form-field &-subscript-wrapper {
    left: 0;
    top: 35px;
    padding: 0;
    z-index: 1;
    margin-top: 0;
    line-height: 0;
    font-size: 75%;
    position: absolute;
    letter-spacing: unset;
    font-family: var(--font-family-poppins);
    .mat-mdc-form-field {
      &-hint-wrapper, &-error-wrapper {
        padding: 0;
      }
    }
    &.mat-mdc-form-field-bottom-align:before {
        height: 0;
        line-height: 0;
        display: unset;
      }
    .mat-mdc-form-field-bottom-align:before {
        height: 0;
        line-height: 0;
        display: unset;
      }
  }
  &-error {
    line-height: 1;
    font-size: 10px;
    margin-top: 8px;
    font-weight: 600;
    padding: 6px 12px;
    letter-spacing: .4px;
    color: var(--color-warning) !important;
    border-radius: var(--base-border-radius);
    background-color: var(--color-warning-secondary);
  }
  &-type-mat-select {
    .mdc-text-field {
      &--outlined, &--no-label {
        .mat-mdc-form-field-infix {
          line-height: 16px;
          padding: 8px 0 7px 0;
        }
      }
    }
    .mat-mdc-select-disabled .mat-mdc-select-trigger {
      .mat-mdc-select-value {
        opacity: .5;
        color: var(--field--disabled-text-color);
      }
    }
    .mat-mdc-select {
      .mat-mdc-select-placeholder {
        font-weight: 600;
        font-size: var(--font-size-ssm);
        color: var(--field-placeholder-color);
        font-family: var(--font-family-poppins);
      }
      &-arrow-wrapper {
        width: 14px;
        margin-left: 4px;
      }
      &-trigger {
        height: auto;
      }
      &-value {
        font-weight: 600;
        letter-spacing: normal;
        color: var(--field-text-color);
        font-size: var(--font-size-ssm);
        font-family: var(--font-family-poppins);
      }
    }
  }
  .mat-mdc-form-field-icon-suffix {
    margin-right: 13px;
    .mat-datepicker-toggle {
      .mat-mdc-icon-button.mat-mdc-button-base {
        padding: 6px;
        width: 31.5px;
        height: 31.5px;
      }
    }
  }
}

.mat-mdc-input-element:disabled {
  color: inherit;
}

div.mat-mdc-select-panel, div.mat-mdc-autocomplete-panel {
  padding: 0 !important;
  .mat-mdc-option {
    height: auto;
    font-weight: 600;
    min-height: unset;
    line-height: 20px ;
    white-space: unset;
    padding: 8px 16px;
    letter-spacing: unset;
    overflow-wrap: anywhere;
    font-size: var(--font-size-ssm);
    color: var(--option-text-color);
    font-family: var(--font-family-poppins);
    border-bottom: 1px solid var(--border-color-primary);
    &.mat-mdc-option-active.mdc-list-item, &.mdc-list-item--selected:not(.mdc-list-item--disabled), .mdc-list-item--selected {
      background-color: var(--selected-option-bg);
      .mdc-list-item__primary-text {
        color: var(--option-text-color);
      }
      &:not(.mat-mdc-option-multiple) .mat-pseudo-checkbox {
        display: none;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  font-weight: 600;
  color: var(--field-text-color) !important;
  font-size: var(--font-size-ssm) !important;
  font-family: var(--font-family-poppins) !important;
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}
/*Change text in autofill text box*/
input:-webkit-autofill {
  font-weight: 600;
  -webkit-text-fill-color: inherit;
  color: var(--field-text-color) !important;
  font-size: var(--font-size-ssm) !important;
  font-family: var(--font-family-poppins) !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea.mat-mdc-input-element.cdk-textarea-autosize {
  padding: 2px 0;
  margin: -2px 0;
  font-weight: 600;
  line-height: 14px;
  box-sizing: content-box;
  color: var(--field-text-color);
  font-size: var(--font-size-ssm);
  font-family: var(--font-family-poppins);
}
