._p {
  &-0 {
    padding: 0;
  }
  &-5 {
    padding: 0.5rem;
  }
  &-8 {
    padding: 0.8rem;
  }
  &-10 {
    padding: 1rem;
  }
  &-12 {
    padding: 1.2rem;
  }
  &-16 {
    padding: 1.6rem;
  }
  &-20 {
    padding: 2rem;
  }
  &-24 {
    padding: 2.4rem;
  }
  &-25 {
    padding: 2.5rem;
  }
  &-30 {
    padding: 3rem;
  }
  &-35 {
    padding: 3.5rem;
  }
  &-40 {
    padding: 4rem;
  }
}

._pt {
  &-0 {
    padding-top: 0;
  }
  &-5 {
    padding-top: .5rem;
  }
  &-10 {
    padding-top: 1rem;
  }
  &-12 {
    padding-top: 1.2rem;
  }
  &-14 {
    padding-top: 1.4rem;
  }
  &-16 {
    padding-top: 1.6rem;
  }
  &-20 {
    padding-top: 2rem;
  }
  &-24 {
    padding-top: 2.4rem;
  }
  &-25 {
    padding-top: 2.5rem;
  }
  &-30 {
    padding-top: 3rem;
  }
  &-35 {
    padding-top: 3.5rem;
  }
  &-40 {
    padding-top: 4rem;
  }
  &-50 {
    padding-top: 5rem;
  }
}

._pb {
  &-5 {
    padding-bottom: .5rem;
  }
  &-8 {
    padding-bottom: .8rem;
  }
  &-10 {
    padding-bottom: 1rem;
  }
  &-12 {
    padding-bottom: 1.2rem;
  }
  &-14 {
    padding-bottom: 1.4rem;
  }
  &-16 {
    padding-bottom: 1.6rem;
  }
  &-20 {
    padding-bottom: 2rem;
  }
  &-24 {
    padding-bottom: 2.4rem;
  }
  &-25 {
    padding-bottom: 2.5rem;
  }
  &-30 {
    padding-bottom: 3rem;
  }
  &-50 {
    padding-bottom: 5rem;
  }
}

._pr {
  &-5 {
    padding-right: .5rem;
  }
  &-8 {
    padding-right: .8rem;
  }
  &-10 {
    padding-right: 1rem;
  }
  &-12 {
    padding-right: 1.2rem;
  }
  &-14 {
    padding-right: 1.4rem;
  }
  &-15 {
    padding-right: 1.5rem;
  }
  &-18 {
    padding-right: 1.8rem;
  }
  &-20 {
    padding-right: 2rem;
  }
  &-24 {
    padding-right: 2.4rem;
  }
  &-40 {
    padding-right: 4rem;
  }
}

._pl {
  &-0 {
    padding-left: 0;
  }
  &-5 {
    padding-left: .5rem;
  }
  &-10 {
    padding-left: 1rem;
  }
  &-12 {
    padding-left: 1.2rem;
  }
  &-15 {
    padding-left: 1.5rem;
  }
  &-16 {
    padding-left: 1.6rem;
  }
  &-18 {
    padding-left: 1.8rem;
  }
  &-20 {
    padding-left: 2rem;
  }
  &-25 {
    padding-left: 2.5rem;
  }
  &-28 {
    padding-left: 2.8rem;
  }
  &-34 {
    padding-left: 3.4rem;
  }
  &-40 {
    padding-left: 4rem;
  }
}

._ph {
  &-5 {
    padding: 0 .5rem;
  }
  &-6 {
    padding: 0 .6rem;
  }
  &-8 {
    padding: 0 .8rem;
  }
  &-10 {
    padding: 0 1rem;
  }
  &-12 {
    padding: 0 1.2rem;
  }
  &-16 {
    padding: 0 1.6rem;
  }
  &-18 {
    padding: 0 1.8rem;
  }
  &-20 {
    padding: 0 2rem;
  }
  &-25 {
    padding: 0 2.5rem;
  }
  &-36 {
    padding: 0 3.6rem;
  }
}

._pv {
  &-5 {
    padding: .5rem 0;
  }
  &-10 {
    padding: 1rem 0;
  }
  &-12 {
    padding: 1.2rem 0;
  }
  &-20 {
    padding: 2rem 0;
  }
  &-24 {
    padding: 2.4rem 0;
  }
}
