table.dataTable thead {
  .sorting_asc,
  .sorting_desc {
    background: none;
  }
  .sorting_desc .data-table__header-icon ._desc,
  .sorting_asc .data-table__header-icon ._asc {
    color: var(--icon-color-primary);
  }
}

.data-table {
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    .sorting_disabled .data-table__header-icon {
      ._asc, ._desc {
        display: none;
      }
    }
    .dataTables_wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      & .dataTables_scroll {
        clear: both;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
      & .dataTables_scroll div.dataTables_scrollBody {
        height: 100%;
      }
    }
    &._full-html-table {
      .dataTables_empty {
        display: none;
      }
    }
  }
  &__page-actions {
    display: flex;
    align-items: center;
    padding: 0 15px 22px;
    button {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
    padding-right: 50px;
    justify-content: flex-end;
    min-height: var(--table-actions-height);
    border-bottom: 1px solid var(--table-border-color-primary);
    &-wrapper {
      height: var(--table-header-height);
    }
    &-icon {
      display: flex;
      flex-direction: column;
      font-size: 8px;
      width: 10px;
      padding-bottom: 5px;
      ._asc, ._desc {
        height: 6px;
        color: var(--table-sort-icon-color);
      }
    }
    .dataTables_info {
      color: var(--text-color-secondary);
      font-size: var(--font-size-ssm);
      padding: 0 10px 0 0;
      line-height: 27px; // this parameters should be equal, if they placed in one line
    }
    .dataTables_length {
      color: var(--text-color-secondary);
      font-size: var(--font-size-ssm);
      line-height: 27px; // this parameters should be equal, if they placed in one line
      margin-left: 10px;
      z-index: 1;
      select {
        border-color: var(--border-color-primary);
        color: var(--text-color-primary);
      }
      label {
        margin: 0;
        font-weight: normal;
        color: var(--text-color-secondary);
        font-size: var(--font-size-ssm);
      }
    }
  }
  &__cell {
    &-action {
      padding: 0;
      width: 32px;
      height: 32px;
      border: none;
      outline: none;
      display: flex;
      background: none;
      border-radius: var(--base-border-radius);
      &:hover svg {
        fill: var(--icon-color-primary);
      }
      svg {
        width: 16px;
        height: 16px;
        margin: auto;
        transition: fill .2s ease-out;
        fill: var(--icon-color-secondary);
      }
      &._warn-table-action {
        background-color: var(--btn-icon-warn-bg);
        svg {
          fill: var(--btn-icon-warn-svg);
        }
        &:hover svg {
          fill: var(--btn-icon-warn-svg);
        }
      }
    }
  }
  &__icon {
    width: 14px;
    height: 14px;
    line-height: 14px;
    display: inline-block;
    fill: var(--icon-color-secondary);
    &._l {
      width: 24px;
      height: 24px;
    }
    &._date {
      margin-right: 6px;
      width: 14px;
      height: 15px;
      line-height: 15px;
    }
    &._menu {
      margin-right: 16px;
      width: 20px;
    }
    &._note {
      height: 15px;
      width: 15px;
    }
  }
  &__highlight-info {
    min-width: 36px;
    padding: 2px 6px;
    text-align: center;
    display: inline-block;
    font-size: var(--font-size-sxm);
    background-color: var(--color-primary);
    border-radius: var(--base-border-radius);
    &._warning {
      background-color: var(--color-warning-alternative);
      color: var(--color-warning-secondary);
    }
    &._success {
      background-color: var(--color-success-primary);
      color: var(--color-success-alternative);
    }
  }
  &__img {
    width: 88px;
    height: 50px;
    img {
      object-fit: contain;
    }
  }
}

table.dataTable td {
  &._amount {
    text-align: right;
  }
  &._ext-code {
    word-break: break-all;
  }
}
