.custom-btn {
  &__link {
    display: inline-block;
    color: var(--btn-link-text);
    font-size: var(--font-size-ssm);
    transition: color .2s ease-out;
    &._text-accent-light {
      color: var(--text-color-accent-light);
    }
    &._l {
      font-size: var(--font-size-xl);
    }
    &._m {
      font-size: var(--font-size-m);
    }
  }
}
