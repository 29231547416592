._fs,
.mat-mdc-button._fs, .mat-mdc-unelevated-button._fs, .mat-mdc-raised-button._fs, .mat-mdc-fab._fs, .mat-mdc-mini-fab._fs, .mat-mdc-icon-button._fs, .mat-mdc-button-base.mat-link-btn._fs {
  &-10 {
    font-size: 1rem;
  }
  &-11 {
    font-size: 1.1rem;
  }
  &-12 {
    font-size: 1.2rem;
  }
  &-13 {
    font-size: 1.3rem;
  }
  &-14 {
    font-size: 1.4rem;
  }
  &-15 {
    font-size: 1.5rem;
  }
  &-16 {
    font-size: 1.6rem;
  }
  &-18 {
    font-size: 1.8rem;
  }
  &-19 {
    font-size: 1.9rem;
  }
  &-20 {
    font-size: 2.0rem;
  }
  &-21 {
    font-size: 2.1rem;
  }
  &-22 {
    font-size: 2.2rem;
  }
  &-32 {
    font-size: 3.2rem;
  }
  &-36 {
    font-size: 3.6rem;
  }
}

._fw,
table.dataTable td._fw,
.mat-mdc-button._fw, .mat-mdc-unelevated-button._fw, .mat-mdc-raised-button._fw, .mat-mdc-fab._fw, .mat-mdc-mini-fab._fw, .mat-mdc-icon-button._fw, .mat-mdc-button-base.mat-link-btn._fw {
  &-300 {
    font-weight: 300;
  }
  &-400 {
    font-weight: 400;
  }
  &-500 {
    font-weight: 500;
  }
  &-600 {
    font-weight: 600;
  }
  &-700 {
    font-weight: 700;
  }
  &-800 {
    font-weight: 800;
  }
  &-900 {
    font-weight: 900;
  }
}

._letter-spacing {
  &-minus {
    &-1 {
      letter-spacing: -.1px;
    }
    &-2 {
      letter-spacing: -.2px;
    }
    &-4 {
      letter-spacing: -.4px;
    }
    &-7 {
      letter-spacing: -.7px;
    }
  }
  &-0 {
    letter-spacing: 0;
  }
  &-1 {
    letter-spacing: .1px;
  }
  &-2 {
    letter-spacing: .2px;
  }
  &-3 {
    letter-spacing: .3px;
  }
  &-4 {
    letter-spacing: .4px;
  }
  &-5 {
    letter-spacing: .5px;
  }
  &-6 {
    letter-spacing: .6px;
  }
  &-7 {
    letter-spacing: .7px;
  }
  &-8 {
    letter-spacing: .8px;
  }
}

._line-height {
  &-base {
    &-12 {
      line-height: 1.2;
    }
    &-13 {
      line-height: 1.3;
    }
    &-14 {
      line-height: 1.4;
    }
  }
  &-reset {
    line-height: 1;
  }
  &-14 {
    line-height: 1.4rem;
  }
  &-16 {
    line-height: 1.6rem;
  }
  &-17 {
    line-height: 1.7rem;
  }
  &-20 {
    line-height: 2rem;
  }
  &-21 {
    line-height: 2.1rem;
  }
  &-22 {
    line-height: 2.2rem;
  }
  &-24 {
    line-height: 2.4rem;
  }
  &-25 {
    line-height: 2.5rem;
  }
  &-30 {
    line-height: 3.0rem;
  }
  &-32 {
    line-height: 3.2rem;
  }
  &-34 {
    line-height: 3.4rem;
  }
  &-40 {
    line-height: 4rem;
  }
}
