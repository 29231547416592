.markdown-body {
  img {
    width: auto;
    display: block;
    height: initial;
    max-width: 100%;
  }

  a {
    color: #0645ad;
    text-decoration: none;
  }

  a:visited {
    color: #0b0080;
  }

  a:hover {
    color: #06e;
  }

  a:active {
    color: #faa700;
  }

  a:focus {
    outline: thin dotted;
  }

  a:hover, a:active {
    outline: 0;
  }

  ::-moz-selection {
    background: rgba(255, 255, 0, 0.3);
    color: #000
  }

  ::selection {
    background: rgba(255, 255, 0, 0.3);
    color: #000
  }

  a::-moz-selection {
    background: rgba(255, 255, 0, 0.3);
    color: #0645ad
  }

  a::selection {
    background: rgba(255, 255, 0, 0.3);
    color: #0645ad
  }

  p {
    margin: 1em 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    margin: 1em 0;
  }

  h4, h5, h6 {
    font-weight: 600;
  }

  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.2em;
  }

  h5 {
    font-size: 1em;
  }

  h6 {
    font-size: 1em;
  }

  blockquote {
    margin: 0;
    padding-left: 3em;
    border-left: 0.5em #EEE solid;
  }

  hr {
    display: block;
    height: 0;
    border: 0;
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #eee;
    margin: 1em 0;
    padding: 0;
  }

  pre, code, kbd, samp {
    font-family: monospace, monospace;
    _font-family: 'courier new', monospace;
    font-size: 0.98em;
  }

  pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  b, strong {
    font-weight: 700;
  }

  dfn {
    font-style: italic;
  }

  ins {
    background: #ff9;
    text-decoration: none;
  }

  mark {
    background: #ff0;
    font-style: italic;
    font-weight: bold;
  }

  sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  ul, ol {
    margin: 1em 0;
    padding: 0 0 0 2em;
    list-style: initial
  }

  li p:last-child {
    margin: 0
  }

  dd {
    margin: 0 0 0 2em;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td {
    vertical-align: top;
  }

  @media only screen and (min-width: 480px) {
    body {
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 768px) {
    body {
      font-size: 18px;
    }
  }

  @media print {
    * {
      background: transparent !important;
      color: black !important;
      filter: none !important;
      -ms-filter: none !important;
    }
    body {
      font-size: 12pt;
      max-width: 100%;
    }
    a, a:visited {
      text-decoration: underline;
    }
    hr {
      height: 1px;
      border: 0;
      border-bottom: 1px solid black;
    }
    a[href]:after {
      content: " (" attr(href) ")";
    }
    abbr[title]:after {
      content: " (" attr(title) ")";
    }
    .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
      content: "";
    }
    pre, blockquote {
      border: 1px solid #999;
      padding-right: 1em;
      page-break-inside: avoid;
    }
    tr {
      page-break-inside: avoid;
    }
    @page :left {
      margin: 15mm 20mm 15mm 10mm;
    }
    @page :right {
      margin: 15mm 10mm 15mm 20mm;
    }
    p, h2, h3 {
      orphans: 3;
      widows: 3;
    }
    h2, h3 {
      page-break-after: avoid;
    }
  }
}
