@mixin customScroll($isHorizontal: false, $width: 4px)
  scrollbar-base-color: transparent
  scrollbar-3dlight-color: transparent
  scrollbar-arrow-color: #ccc
  scrollbar-track-color: rgba(#fff,.01)
  scrollbar-darkshadow-color: rgba(#fff,.01)
  scrollbar-face-color: #ccc
  scrollbar-highlight-color: rgba(#fff,.01)
  scrollbar-shadow-color: #ccc
  &::-webkit-scrollbar
    @if $isHorizontal
      height: 4px
    @else
      width: $width
  &::-webkit-scrollbar-thumb
    @if $isHorizontal
      background: #9B9B9B
    @else
      background: #9B9B9B
      border: 1px solid #E7E6E6
      border-radius: 2px
  &::-webkit-scrollbar-track
    background: #E7E6E6
    border-radius: 2px

@mixin customScrollDark($isHorizontal: false, $width: 2px)
  scrollbar-base-color: transparent
  scrollbar-3dlight-color: transparent
  scrollbar-arrow-color: #fff
  scrollbar-track-color: rgba(#000,.01)
  scrollbar-darkshadow-color: rgba(#000,.01)
  scrollbar-face-color: #fff
  scrollbar-highlight-color: rgba(#000,.01)
  scrollbar-shadow-color: #fff
  &::-webkit-scrollbar
    @if $isHorizontal
      height: 17px
    @else
      width: $width
  &::-webkit-scrollbar-thumb
    @if $isHorizontal
      background: linear-gradient(to top, #fff 0, #fff 2px, transparent 2px, transparent)
      //background #fff
    @else
      background: #fff
  &::-webkit-scrollbar-track
    background: transparent

@mixin customScroll_2($width: 15px)
  &::-webkit-scrollbar
    width: $width
  &::-webkit-scrollbar-thumb
    background: #656d78
    width: $width
    height: $width
    border-radius: 8px
  &::-webkit-scrollbar-track
    width: $width
    background: #656d78
    box-shadow: 7px 0 0 #fff inset,-7px 0 0 #fff inset
  @media only screen and (max-width: 860px)
    &::-webkit-scrollbar-thumb
      border-left: 3px solid #fff
      border-right: 3px solid #fff
