._label {
  padding: 4px 8px;
  font-weight: 600;
  font-size: var(--font-size-ssm);
  border-radius: var(--base-border-radius);
  &._bold {
    font-weight: 700;
  }
  &._label-min-width {
    min-width: 110px;
  }
  &._label-line-height {
    &-18 {
      line-height: 18px;
    }
    &-22 {
      line-height: 22px;
    }
    &-24 {
      line-height: 24px;
    }
    &-34 {
      line-height: 34px;
    }
  }
  &._base {
    color: var(--color-secondary);
    background-color: var(--color-secondary-mediym);
  }
  &._active {
    color: var(--color-primary);
    background-color: var(--color-primary-light);
  }
  &._accent {
    color: var(--color-note-secondary);
    background-color: var(--color-note);
  }
  &._note {
    color: var(--color-note);
    background-color: var(--color-note-secondary);
  }
  &._success {
    color: var(--color-success);
    background-color: var(--color-success-light);
  }
  &._success-v1 {
    color: var(--text-color-primary-dark);
    background-color: var(--color-success-v1);
  }
  &._error {
    color: var(--color-warning);
    background-color: var(--color-warning-secondary);
  }
}

._error-label {
  font-weight: 600;
  padding: 14px 24px;
  display: inline-block;
  font-size: var(--font-size-ssm);
  border: 1px solid var(--color-warning);
  border-radius: var(--base-border-radius);
  background-color: var(--color-warning-secondary);
  &._d-f {
    display: flex;
  }
}
