.mat-mdc-tab {
  &-body-content {
    padding: 0 24px;
  }
  &-header {
    padding: 0 24px;
    border-bottom: 1px solid rgba(0,0,0,.12);
  }
  &-body-wrapper,
  &-group {
    height: 100%;
    font-family: var(--font-family-poppins);
    &._label-ph-20 {
      .mdc-tab {
        padding: 0 20px;
      }
    }
    &._no-body {
      height: auto;
      .mat-mdc-tab-body-wrapper {
        display: none;
      }
    }
    &._no-active {
      .mdc-tab-indicator {
        display: none;
      }
      .mdc-tab {
        &:hover {
          .mdc-tab__content {
            color: var(--icon-color-secondary);
          }
        }
        &--active {
          border: none;
          .mdc-tab__content {
            color: var(--icon-color-secondary);
            .mdc-tab__text-label {
              color: var(--text-color-secondary);
            }
          }
          &:focus, &:hover {
            .mdc-tab__content {
              color: var(--icon-color-secondary);
              .mdc-tab__text-label {
                color: var(--text-color-secondary);
              }
            }
          }
          .mat-tab-label-icon.mat-icon {
            color: var(--icon-color-secondary);
          }
        }
      }
    }
    &._no-horizontal-space {
      .mdc-tab__content,
      .mat-mdc-tab-header {
        padding: 0;
      }
    }
    &._overflow-unset {
      .mat-mdc-tab-body-wrapper, .mat-mdc-tab-body.mat-mdc-tab-body-active, .mat-mdc-tab-body-content {
        overflow: unset;
      }
    }
    .mdc-tab {
      opacity: 1;
      padding: 0 12px;
      min-width: 100px;
      .mat-tab-label-icon.mat-icon {
        width: 18px;
        height: 18px;
        margin-right: 12px;
        color: var(--icon-color-secondary);
      }
      &--active {
        &.mat-mdc-tab .mdc-tab__text-label, .mdc-tab-indicator__content--underline {
          color: var(--text-color-accent);
          border-color: var(--text-color-accent);
        }
        &:focus {
          &.mat-mdc-tab .mdc-tab__text-label, &.mat-mdc-tab .mdc-tab-indicator__content--underline {
            color: var(--text-color-accent);
            border-color: var(--text-color-accent);
          }
        }
        &:hover {
          &.mat-mdc-tab .mdc-tab__text-label, &.mat-mdc-tab .mdc-tab-indicator__content--underline {
            color: var(--text-color-accent);
            border-color: var(--text-color-accent);
          }
        }
        .mat-tab-label-icon.mat-icon {
          color: var(--icon-color-primary);
        }
      }
      &__content .mdc-tab__text-label {
        font-weight: 700;
        letter-spacing: normal;
        transition: color .2s ease-out;
        font-size: var(--font-size-ssm);
        color: var(--text-color-secondary);
        font-family: var(--font-family-poppins), sans-serif;
      }
      &__ripple {
        display: none;
      }
    }
    &._bordered {
      border-radius: var(--base-border-radius);
      border: 1px solid var(--border-color-primary);
    }
    &._border-warning-full {
      border: 1px solid var(--color-warning);
    }
    &._auto-width-label {
      .mdc-tab {
        padding: 0;
        width: auto;
        min-width: auto;
        &:not(:last-child) {
          margin-right: 24px;
        }
      }
    }
    &._without-pagination {
      .mat-mdc-tab-header {
        padding: 0;
        border-bottom: none;
      }
      .mdc-tab__content {
        padding: 0;
      }
      .mat-mdc-tab-labels {
        flex-wrap: wrap !important;
      }
      .mdc-tab {
        overflow: unset;
        &.first-tab-in-row:before {
          left: 0;
          bottom: 0;
          content: '';
          height: 1px;
          width: 1000px;
          position: absolute;
          background-color: var(--border-color-primary);
        }
      }
      .mdc-tab--active {
        position: relative;
        border-bottom: 1px solid var(--color-primary);
        &:after {
          bottom: 0;
          content: '';
          height: 2px;
          width: 100%;
          position: absolute;
          background-color: var(--color-primary);
        }
      }
      .mdc-tab-indicator {
        display: none !important;
      }
    }
    &._no-tab-border {
      .mat-mdc-tab-header {
        border-bottom: none;
      }
    }
    &._no-header {
      .mat-mdc-tab-header {
        display: none;
      }
    }
    .mdc-tab-ripple:not(:empty) {
      transform: none;
    }
  }
}
