.ngx-pagination {
  margin: 0 !important;
  font-size: 13px;
  font-weight: 600;
  & li {
    &:not(:last-child) {
      margin-right: 2px !important;
    }
  }
  .pagination-next a:hover,
  .pagination-previous a:hover, {
    background: none;
    border: 1px solid transparent;
    color: #333;
  }
  & a, & button {
    color: #828D96 !important;
    border: 1px solid transparent;
  }
  & .current {
    color: white;
    border-radius: 4px;
    border: 1px solid #828D96;
    background: #0088F7 !important;
  }
}
.ngx-pagination a:hover, .ngx-pagination button:hover {
  color: white !important;
  border-radius: 4px;
  border: 1px solid #828D96;
  background: #828D96 !important;
}
.ngx-pagination .current,
.ngx-pagination .disabled,
.ngx-pagination a, .ngx-pagination button {
  padding: 0 5px !important;
  height: 20px;
  line-height: 20px;
}

.ngx-pagination .pagination-previous a::before, .ngx-pagination .pagination-previous.disabled::before,
.ngx-pagination .pagination-next a::after, .ngx-pagination .pagination-next.disabled::after {
  margin: 0 !important;
}
