._d-b,
.mat-mdc-button._d-b, .mat-mdc-icon-button._d-b, .mat-mdc-link-btn._d-b, .mat-mdc-unelevated-button._d-b, .mat-mdc-raised-button._d-b, .mat-mdc-fab._d-b, .mat-mdc-mini-fab._d-b {
  display: block;
}

._d-n {
  display: none;
  &-i {
    display: none !important;
  }
}

._d-f,
.mat-mdc-unelevated-button._d-f {
  display: flex;
  ._d-n {
    display: none;
  }
}

._d-ib {
  display: inline-block;
}

._d-i {
  display: inline;
}

._visibility {
  &-hidden {
    visibility: hidden;
  }
}

._pointer-events {
  &-none {
    pointer-events: none;
  }
}
