._relative {
  position: relative;
}

._fixed {
  position: fixed;
}

._absolute,
.mat-mdc-button._absolute, .mat-mdc-unelevated-button._absolute, .mat-mdc-raised-button._absolute, .mat-mdc-fab._absolute, .mat-mdc-mini-fab._absolute, .mat-mdc-icon-button._absolute, .mat-mdc-link-btn._absolute {
  position: absolute;
  &-full-size {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

._z-index {
  &-1 {
    z-index: 1;
  }
}

._right {
  &-12 {
    right: 12px;
  }
}

._left {
  &-6 {
    left: 6px;
  }
}

._top {
  &-12 {
    top: 12px;
  }
  &-minus {
    &-38 {
      top: -38px;
    }
  }
}
