.mat-mdc-checkbox {
  .mdc-form-field {
    font-weight: 500;
    letter-spacing: unset;
    align-items: baseline;
    color: var(--color-secondary-dark);
    font-family: var(--font-family-poppins);
  }
  &._hidden {
    display: none;
  }
  &-disabled {
    opacity: .5;
  }
  .mdc-label {
    line-height: 1.3;
    font-weight: 500;
    letter-spacing: 0;
    white-space: break-spaces;
    color: var(--color-secondary-dark);
    font-family: var(--font-family-poppins);
  }
  .mdc-checkbox {
    top: 3px;
    padding: 0;
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
    text-align: left;
    margin: 0 4px 0 0;
    &__ripple {
      display: none;
    }
  }
  &._text-justify {
    .mdc-label {
      text-align: justify;
      white-space: pre-line;
    }
  }
  &._no-label {
    width: 16px;
    height: 16px;
    line-height: 16px;
    .mdc-checkbox {
      top: 0;
      margin: 0;
    }
  }
  &__item {
    position: relative;
    &._error {
      .mat-mdc-checkbox {
        padding: 5px;
        border: 1px solid var(--color-warning);
        border-radius: var(--base-border-radius);
      }
    }
    .mat-mdc-form-field-error {
      margin-top: 3px;
      position: relative;
      &:before {
        width: 0;
        height: 0;
        z-index: 1;
        top: -7px;
        left: 12px;
        content: '';
        position: absolute;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid var(--color-warning-secondary);
      }
    }
  }
  .mdc-checkbox {
    .mdc-checkbox__checkmark, .mdc-checkbox__background {
      width: 16px;
      top: unset ;
      left: unset;
      height: 16px;
      border-width: 1px;
      padding: 3px 1px 1px 3px;
      border-radius: var(--base-border-radius);
      border-color: var(--toggle-items-secondary-color);
      --mdc-checkbox-selected-pressed-icon-color: transparent;
      --mdc-checkbox-unselected-icon-color: var(--toggle-items-secondary-color);
      --mdc-checkbox-unselected-hover-icon-color: var(--toggle-items-secondary-color);
      --mdc-checkbox-unselected-focus-icon-color: var(--toggle-items-secondary-color);
    }
  }
}

.mat-mdc-checkbox.mat-primary .mdc-checkbox .mdc-checkbox__native-control {
  &:enabled:checked, &:enabled:indeterminate, &[data-indeterminate=true]:enabled {
    ~ .mdc-checkbox__background {
      border-color: var(--toggle-items-primary-color);
      background-color: var(--toggle-items-primary-color);
    }
  }
}

.mat-mdc-checkbox.mat-primary-dark .mdc-checkbox .mdc-checkbox__native-control {
  &:enabled:checked, &:enabled:indeterminate, &[data-indeterminate=true]:enabled {
    ~ .mdc-checkbox__background {
      border-color: var(--toggle-items-primary-dark-color);
      background-color: var(--toggle-items-primary-dark-color);
    }
  }
}


.mat-mdc-option-multiple {
  .mat-pseudo-checkbox {
    width: 16px;
    height: 16px;
    border-width: 1px;
    align-self: center;
    border-radius: var(--base-border-radius);
    border-color: var(--toggle-items-secondary-color);
    &.mat-pseudo-checkbox-full {
      margin-right: 8px;
    }
  }
  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
    width: 8px;
    height: 3px;
  }
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
  background-color: var(--toggle-items-primary-color);
}

