@font-face {
  font-family: 'DMEPoppins';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(./../../../assets/fonts/Poppins-Light.woff2) format('woff2'),
  url(./../../../assets/fonts/Poppins-Light.woff) format('woff');
}
@font-face {
  font-family: 'DMEPoppins';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(./../../../assets/fonts/Poppins-Regular.woff2) format('woff2'),
  url(./../../../assets/fonts/Poppins-Regular.woff) format('woff');
}
@font-face {
  font-family: 'DMEPoppins';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(./../../../assets/fonts/Poppins-Medium.woff2) format('woff2'),
  url(./../../../assets/fonts/Poppins-Medium.woff) format('woff');
}
@font-face {
  font-family: 'DMEPoppins';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url(./../../../assets/fonts/Poppins-SemiBold.woff2) format('woff2'),
  url(./../../../assets/fonts/Poppins-SemiBold.woff) format('woff');
}
@font-face {
  font-family: 'DMEPoppins';
  font-style: normal;
  font-weight: 800;
  font-display: block;
  src: url(./../../../assets/fonts/Poppins-Bold.woff2) format('woff2'),
  url(./../../../assets/fonts/Poppins-Bold.woff) format('woff');
}
@font-face {
  font-family: 'PlusJakartaSans';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(./../../../assets/fonts/PlusJakartaSans-Regular.woff2) format('woff2'),
  url(./../../../assets/fonts/PlusJakartaSans-Regular.woff) format('woff');
}
@font-face {
  font-family: 'PlusJakartaSans';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url(./../../../assets/fonts/PlusJakartaSans-Medium.woff2) format('woff2'),
  url(./../../../assets/fonts/PlusJakartaSans-Medium.woff) format('woff');
}
@font-face {
  font-family: 'PlusJakartaSans';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url(./../../../assets/fonts/PlusJakartaSans-SemiBold.woff2) format('woff2'),
  url(./../../../assets/fonts/PlusJakartaSans-SemiBold.woff) format('woff');
}
