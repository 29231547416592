._m,
.mat-mdc-button._m, .mat-mdc-icon-button._m, .mat-mdc-link-btn._m, .mat-mdc-unelevated-button._m, .mat-mdc-raised-button._m, .mat-mdc-fab._m, .mat-mdc-mini-fab._m {
  &-5 {
    margin: 5px;
  }
  &-10 {
    margin: 10px;
  }
  &-auto {
    margin: auto;
  }
  &-zero-auto {
    margin: 0 auto;
  }
}

._mb,
.custom-form .custom-form__combine-items .custom-form__item._mb, .custom-form__item:not(:last-child)._mb,
.mat-mdc-button._mb, .mat-mdc-icon-button._mb, .mat-mdc-link-btn._mb, .mat-mdc-unelevated-button._mb, .mat-mdc-raised-button._mb, .mat-mdc-fab._mb, .mat-mdc-mini-fab._mb {
  &-0 {
    margin-bottom: 0;
  }
  &-5 {
    margin-bottom: .5rem;
  }
  &-8 {
    margin-bottom: .8rem;
  }
  &-10 {
    margin-bottom: 1rem;
  }
  &-12 {
    margin-bottom: 1.2rem;
  }
  &-14 {
    margin-bottom: 1.4rem;
  }
  &-15 {
    margin-bottom: 1.5rem;
  }
  &-16 {
    margin-bottom: 1.6rem;
  }
  &-18 {
    margin-bottom: 1.8rem;
  }
  &-20 {
    margin-bottom: 2rem;
  }
  &-21 {
    margin-bottom: 2.1rem;
  }
  &-22 {
    margin-bottom: 2.2rem;
  }
  &-24 {
    margin-bottom: 2.4rem;
  }
  &-25 {
    margin-bottom: 2.5rem;
  }
  &-26 {
    margin-bottom: 2.6rem;
  }
  &-27 {
    margin-bottom: 2.7rem;
  }
  &-30 {
    margin-bottom: 3rem;
  }
  &-32 {
    margin-bottom: 3.2rem;
  }
  &-35 {
    margin-bottom: 3.5rem;
  }
  &-38 {
    margin-bottom: 3.8rem;
  }
  &-40 {
    margin-bottom: 4rem;
  }
  &-48 {
    margin-bottom: 4.8rem;
  }
  &-50 {
    margin-bottom: 5rem;
  }
  &-72 {
    margin-bottom: 7.2rem;
  }
  &-auto {
    margin-bottom: auto;
  }
}

._ml,
.mat-mdc-button._ml, .mat-mdc-icon-button._ml, .mat-mdc-link-btn._ml, .mat-mdc-unelevated-button._ml, .mat-mdc-raised-button._ml, .mat-mdc-fab._ml, .mat-mdc-mini-fab._ml, .mdc-button .mdc-button__label+.mat-icon._ml {
  &-0 {
    margin-left: 0;
  }
  &-5 {
    margin-left: .5rem;
  }
  &-6 {
    margin-left: .6rem;
  }
  &-8 {
    margin-left: .8rem;
  }
  &-10 {
    margin-left: 1rem;
  }
  &-12 {
    margin-left: 1.2rem;
  }
  &-15 {
    margin-left: 1.5rem;
  }
  &-16 {
    margin-left: 1.6rem;
  }
  &-18 {
    margin-left: 1.8rem;
  }
  &-20 {
    margin-left: 2rem;
  }
  &-24 {
    margin-left: 2.4rem;
  }
  &-40 {
    margin-left: 4rem;
  }
  &-auto {
    margin-left: auto;
  }
}

._mr,
.mdc-button .mat-icon._mr, .mat-mdc-unelevated-button._mr, .mat-mdc-raised-button._mr, .mat-mdc-fab._mr, .mat-mdc-mini-fab._mr {
  &-5 {
    margin-right: .5rem;
  }
  &-8 {
    margin-right: .8rem;
  }
  &-10 {
    margin-right: 1rem;
  }
  &-12 {
    margin-right: 1.2rem;
  }
  &-15 {
    margin-right: 1.5rem;
  }
  &-16 {
    margin-right: 1.6rem;
  }
  &-20 {
    margin-right: 2rem;
  }
  &-24 {
    margin-right: 2.4rem;
  }
  &-40 {
    margin-right: 4rem;
  }
  &-auto {
    margin-right: auto;
  }
}

._mt,
.mat-mdc-button._mt, .mat-mdc-icon-button._mt, .mat-mdc-link-btn._mt, .mat-mdc-unelevated-button._mt, .mat-mdc-raised-button._mt, .mat-mdc-fab._mt, .mat-mdc-mini-fab._mt {
  &-0 {
    margin-top: 0;
  }
  &-3 {
    margin-top: .3rem;
  }
  &-5 {
    margin-top: .5rem;
  }
  &-10 {
    margin-top: 1rem;
  }
  &-12 {
    margin-top: 1.2rem;
  }
  &-14 {
    margin-top: 1.4rem;
  }
  &-15 {
    margin-top: 1.5rem;
  }
  &-20 {
    margin-top: 2rem;
  }
  &-22 {
    margin-top: 2.2rem;
  }
  &-23 {
    margin-top: 2.3rem;
  }
  &-24 {
    margin-top: 2.4rem;
  }
  &-25 {
    margin-top: 2.5rem;
  }
  &-30 {
    margin-top: 3rem;
  }
  &-32 {
    margin-top: 3.2rem;
  }
  &-35 {
    margin-top: 3.5rem;
  }
  &-40 {
    margin-top: 4rem;
  }
  &-50 {
    margin-top: 5rem;
  }
  &-auto {
    margin-top: auto;
  }
}

._m-minus {
  &-5 {
    margin: -.5rem;
  }
  &-10 {
    margin: -1rem;
  }
  &-15 {
    margin: -1.5rem;
  }
  &-20 {
    margin: -2rem;
  }
  &-25 {
    margin: -2.5rem;
  }
  &-30 {
    margin: -3rem;
  }
}

._mh-minus {
  &-5 {
    margin: 0 -.5rem;
  }
  &-10 {
    margin: 0 -1rem;
    &._mb-24 {
      margin: 0 -1rem 2.4rem;
    }
  }
  &-16 {
    margin: 0 -1.6rem;
  }
  &-20 {
    margin: 0 -2rem;
  }
  &-25 {
    margin: 0 -2.5rem;
  }
  &-30 {
    margin: 0 -3rem;
  }
}

._mv-minus {
  &-5 {
    margin: -.5rem 0;
  }
  &-10 {
    margin: -1rem 0;
  }
  &-15 {
    margin: -1.5rem 0;
  }
  &-20 {
    margin: -2rem 0;
  }
  &-25 {
    margin: -2.5rem 0;
  }
  &-30 {
    margin: -3rem 0;
  }
}

._ma {
  margin: auto;
}

._layout-gap-y {
  &-12:not(:last-child) {
    margin-bottom: 1.2rem;
  }
  &-24:not(:last-child) {
    margin-bottom: 2.4rem;
  }
  &-36:not(:last-child) {
    margin-bottom: 3.6rem;
  }
}

._layout-gap-x {
  &._lgx-12 {
    margin: 0 -1.2rem;
    li {
      padding: 0 1.2rem;
    }
  }
}
