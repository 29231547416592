table.dataTable {
  &.row-border tbody th, &.row-border tbody td, &.display tbody th, &.display tbody td {
    border-top: 1px solid var(--table-border-color-primary);
  }
  &.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
    background-color: #F2F5FA;
  }
  &, & td {
    font-size: 13px;
    font-weight: 600;
    color: var(--text-color-secondary);
    &._text-primary {
      color: var(--text-color-primary);
    }
  }
  & tr.selected td.select-checkbox:after, table.dataTable tr.selected th.select-checkbox:after,
  & tbody td.select-checkbox:before, table.dataTable tbody th.select-checkbox:before {
    content: none;
  }
  &.hover tbody tr:hover, tr.odd:hover, table.dataTable.display tbody tr.odd:hover,
  &.hover tbody tr:hover, tr.even:hover, table.dataTable.display tbody tr.even:hover {
    background-color: var(--table-row-hover);
  }
  &.cell-border tbody tr:first-child th,
  &.cell-border tbody tr:first-child {
    td {
      border-top: 1px solid var(--table-border-color-primary);
    }
    &:hover {
      td {
        border-top: 1px solid var(--table-hover-border);
      }
    }
  }
  & tbody > tr.selected,
  & tbody > tr > .selected,
  &.stripe tbody > tr.odd.selected,
  &.stripe tbody > tr.odd > .selected,
  &.display tbody > tr.odd.selected,
  &.display tbody > tr.odd > .selected {
    background-color: var(--table-row-hover) !important;
  }
  thead {
    th:first-child {
      padding: 4px 10px 4px 18px;
    }
    th, td {
      font-size: 13px;
      text-align: left;
      font-weight: normal;
      border-bottom: none;
      padding: 4px 10px 4px 5px;
      vertical-align: bottom;
      color: var(--text-color-secondary);
      &._text-primary {
        color: var(--text-color-primary);
      }
    }
    th.select-checkbox {
      background-image: none;
    }
    .sorting {
      background: none;
    }
  }
  tbody {
    td:first-child {
      padding: 5px 5px 5px 18px;
      border-left: 1px solid transparent;
    }
    td:last-child {
      border-right: 1px solid transparent;
    }
    th, td {
      padding: 5px 5px;
      ._secondary-text-color {
        color: #9c9c9c;
      }
    }
    tr {
      &.selected, & {
        .checkbox-wrapper {
          color: white;
          border: 1px solid #c4c8d0;
          width: 16px;
          height: 16px;
          box-sizing: border-box;
          border-radius: 4px;
          margin: auto;
        }
        &.selected {
          .checkbox-wrapper {
            background-color: var(--color-primary);
            border: 1px solid var(--color-primary);
            &:after {
              content: '\2713';
              text-shadow: none;
              font-size: 12px;
              position: absolute;
              left: calc(50% - -1px);
              top: calc(50% - 8px);
              transform: rotate(0deg);
            }
          }
        }
      }
      //&:hover {
      //  & + tr {
      //    td {
      //      border-top: 1px solid var(--color-primary);
      //    }
      //  }
      //  td {
      //    background-color: transparent;
      //    border-top: 1px solid var(--color-primary);
      //    &:first-child {
      //      border-left: 1px solid var(--color-primary);
      //    }
      //    &:last-child {
      //      border-right: 1px solid var(--color-primary);
      //    }
      //  }
      //}
    }
  }
}


.dataTables_wrapper {
  .dataTables_scrollHead {
    min-height: 49px;
    border-bottom: 1px solid var(--table-border-color-secondary) !important;
  }
  &.no-footer .dataTables_scrollBody {
    min-height: 50px;
    border-bottom: 1px solid var(--table-border-color-secondary);
  }
  .dataTables_paginate {
    font-size: 13px;
    padding: 10px 0;
    align-self: baseline;
    .paginate_button {
      box-shadow: none !important;
      color: var(--text-color-secondary) !important;
      transition: color .2s ease-out;
      font-weight: normal;
      &.previous.disabled {
        opacity: .4;
      }
      &.previous, &.previous:hover, &.next, &.next:hover {
        font-weight: normal;
        border: none !important;
      }
      &.current, &:hover {
        font-weight: normal;
        border-radius: 3px;
        background: var(--color-white);
        color: var(--text-color-primary) !important;
        border: 1px solid var(--table-border-color-secondary);
        &:hover {
          background: var(--color-white);
          border: 1px solid var(--table-border-color-secondary);
        }
      }
    }
  }
}
