.paginator {
  display: flex;
  align-items: center;
  padding: 0 6px;
  height: 100%;
  &._disabled {
    pointer-events: none;
  }
  &__items-per-page {
    font-size: 13px;
    font-weight: 600;
    color: var(--text-color-secondary);
    &-select {
      color: var(--text-color-primary);
      font-size: 13px;
      font-weight: 600;
      text-align: center;
      padding: 2px 4px;
      margin: 0 4px;
      border-radius: var(--base-border-radius);
      border: 1px solid var(--color-secondary);
    }
  }
  &__control {
    margin-left: auto;
  }
}
