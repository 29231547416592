._text {
  &-start {
    text-align: start;
  }
  &-end {
    text-align: end;
  }
  &-center {
    text-align: center;
  }
  &-justify {
    text-align: justify;
  }
  &-vertical {
    &-middle {
      vertical-align: middle;
    }
    &-bottom {
      vertical-align: bottom;
    }
  }
  &-lowercase {
    text-transform: lowercase;
  }
  &-uppercase {
    text-transform: uppercase;
  }
  &-capitalize {
    text-transform: capitalize;
  }
  &-decoration {
    &-underline {
      text-decoration: underline;
    }
  }
  &-no-wrap {
    white-space: nowrap;
  }
  &-initial-wrap {
    white-space: initial;
  }
  &-italic {
    font-style: italic;
  }
  &-break {
    &-word {
      word-break: break-word;
    }
    &-all {
      word-break: break-all;
    }
  }
  &-primary {
    color: var(--text-color-primary);
  }
  &-primary-dark {
    color: var(--text-color-primary-dark);
  }
  &-secondary {
    color: var(--text-color-secondary);
  }
  &-accent {
    color: var(--text-color-accent);
  }
  &-accent-light {
    color: var(--text-color-accent-light);
  }
  &-warning {
    color: var(--color-warning);
  }
  &-note {
    color: var(--color-note);
  }
}

._truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


._required-title {
  position: relative;
  &:after {
    top: -2px;
    content: "*";
    padding-left: 2px;
    position: absolute;
  }
}
